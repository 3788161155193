import { Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/style.scss';
import './assets/css/style-p.scss';
import Home from './pages/Home';
import Registration from './pages/Registration';
import Profile from './pages/Profile';
import Property from './pages/Property';
import StackingFD from './pages/StackingFD';
import Utility from './pages/Utility';
import Dao from './pages/Dao';
import Defi from './pages/Defi';
import Liquidity from './pages/Liquidity';
import Swap from './pages/Swap';
import StackingDep from './pages/StackingDep';
import StackingRD from './pages/StackingRD';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddDao from './pages/AddDao';
import ViewDao from './pages/ViewDao';
import XRGlasses from './pages/XRGlasses';

function App() {
  return (
    <>
      <ToastContainer/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/register' element={<Registration />} />
        <Route path='/xrglasses' element={<XRGlasses />} />
        <Route path='/property' element={<Property />} />
        <Route path='/stackingFD' element={<StackingFD />} />
        <Route path='/stackingRD' element={<StackingRD />} />
        <Route path='/stackingDep' element={<StackingDep />} />
        <Route path='/utility' element={<Utility />} />
        <Route path='/dao' element={<Dao />} />
        <Route path='/add-dao' element={<AddDao />} />
        <Route path='/view-dao/:id' element={<ViewDao />} />
        <Route path='/defi' element={<Defi />} />
        <Route path='/liquidity' element={<Liquidity />} />
        <Route path='/swap' element={<Swap />} />
        <Route path='/stackingFD' element={<StackingFD />} />
        <Route path='/profile' element={<Profile />} />
      </Routes>
    </>
  );
}

export default App;
