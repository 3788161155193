import React, { useEffect, useState } from 'react'
import { useAccount, useContract, useSigner } from 'wagmi';
import Header from '../components/Header'
import { APP_DATA, formatDecimal, fromWei, getContract, toWei } from '../utils/constant';

function Swap() {
	const [activeTop, setActiveTop] = useState("TBC");
	const [activeBottom, setActiveBottom] = useState("TBC");
	const [approve, setApprove] = useState(false);
	const { address } = useAccount()
	const account = address;
	const [amount, setAmount] = useState("");

	const [price1, setPrice1] = useState("");
	const [price2, setPrice2] = useState("");

	const [balance, setBalance] = useState(0);
	const [balance1, setBalance1] = useState(0);

	const { data: signer } = useSigner()
	const contract = useContract({ address: APP_DATA.contractAddress, abi: APP_DATA.abi, signerOrProvider: signer })
	const contractETH = useContract({ address: APP_DATA.contractAddressEth, abi: APP_DATA.abi_eth, signerOrProvider: signer })
	const contractBTC = useContract({ address: APP_DATA.contractAddressBtc, abi: APP_DATA.abi_btc, signerOrProvider: signer })
	const contractBLOOM = useContract({ address: APP_DATA.contractAddressBloom, abi: APP_DATA.abi_bloom, signerOrProvider: signer })
	const contractUSDT = useContract({ address: APP_DATA.contractAddressUsdt, abi: APP_DATA.abi_usdt, signerOrProvider: signer })
	const contractSWAP = useContract({ address: APP_DATA.contractAddressSwap, abi: APP_DATA.abi_swap, signerOrProvider: signer })

	const getBalanceSec = (active = activeBottom) => {
		let contract;
		if (active === "TBC") {
			contract = contractBLOOM;
		} else if (active === "ETH") {
			contract = contractETH;
		} else if (active === "BTC") {
			contract = contractBTC;
		} else if (active === "USDT") {
			contract = contractUSDT;
		}

		contract.balanceOf(account).then(res => {
			setBalance1(res);
		})
	}

	const getBalance = (active = activeTop) => {
		let contract;
		if (active === "TBC") {
			contract = contractBLOOM;
		} else if (active === "ETH") {
			contract = contractETH;
		} else if (active === "BTC") {
			contract = contractBTC;
		} else if (active === "USDT") {
			contract = contractUSDT;
		}

		contract.allowance(account, APP_DATA.contractAddressSwap).then(res => {
			if (res === "0") {
				setApprove(true);
			} else {
				setApprove(false);
			}
		})
		contract.balanceOf(account).then(res => {
			setBalance(res);
		})
	}

	const getPriceMap = (active1 = activeTop, active2 = activeBottom) => {
		contractSWAP.TokenMapping(active1).then(res => {
			setPrice1(res.price);
		})
		contractSWAP.TokenMapping(active2).then(res => {
			setPrice2(res.price);
		})
	}

	useEffect(() => {
		if (signer) {
			getBalance();
			getBalanceSec();
			getPriceMap();
		}

	}, [activeTop, signer]);

	const onApprove = () => {
		let contract;
		if (activeTop === "TBC") {
			contract = contractBLOOM;
		} else if (activeTop === "ETH") {
			contract = contractETH;
		} else if (activeTop === "BTC") {
			contract = contractBTC;
		} else if (activeTop === "USDT") {
			contract = contractUSDT;
		}
		const mAmount = `1` + '0'.repeat(71);
		contract.approve(APP_DATA.contractAddressSwap, mAmount).then(res => {
			res.wait().then(() => {
				window.location.reload();
			});
		})
	}

	const onSwap = () => {
		const mAmount = toWei(amount);
		contractSWAP.swapToken(activeTop, activeBottom, mAmount).then(res => {
			res.wait().then(() => {
				window.location.reload();
			});
		})
	}

	return (
		<div className="w-100 dashboardCover concentricCircles">
			<Header active="swap" />
			<main className="w-100 mainContent liqSwapWrap">
				<div className="container">
					<div className="staticModalBox">
						<div className="bgWrapper">
							<div className="titleRowMain">
								<div className="titleText">
									<img src="/assets/images/swapLogo.svg" alt="Swap Logo" />
									<h1 className="fw600 m-0">Swap</h1>
								</div>
								<div className="groupSidebtns">
									<button className="btn filterBtn">
										<img src="/assets/images/iconSvgs/Filter.svg" alt="" />
									</button>
									<button className="btn filterBtn">
										<img src="/assets/images/iconSvgs/refresh.svg" alt="" />
									</button>
								</div>
							</div>
							<div className="customLiqTabs customSwapTabs">
								<ul className="nav nav-pills" id="pills-tab" role="tablist">
									<li className="nav-item" role="presentation">
										<button className="nav-link active" id="pills-swap-tab" data-bs-toggle="pill" data-bs-target="#pills-swap" type="button" role="tab" aria-controls="pills-swap" aria-selected="false">Swap</button>
									</li>
									<li className="nav-item" role="presentation">
										<button className="nav-link" id="pills-info-tab" data-bs-toggle="pill" data-bs-target="#pills-info" type="button" role="tab" aria-controls="pills-info" aria-selected="false">Info</button>
									</li>
								</ul>
							</div>
							<div className="tab-content" id="pills-tabContent">
								<div className="tab-pane fade show active" id="pills-swap" role="tabpanel" aria-labelledby="pills-swap-tab">
									<div className="w-100 tabPillInnerWrap">
										<div className="stacks">
											<div className="doubleStackRow">
												<div className="eachRow">
													<div className="dropdown">
														<button className="btn border-0 dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
															<div className="eachCrypto">
																<img src="/assets/images/CryptoBUSD.svg" alt="BUSD" />
																<span className="fw500">{activeTop}</span>
															</div>
															<i data-feather="chevron-down" />
														</button>
														<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
															<a className="dropdown-item eachCrypto" onClick={() => { setActiveTop("TBC"); getBalance("TBC"); getPriceMap("TBC") }} href="#">
																<img src="/assets/images/CryptoTBW.svg" alt="BUSD" />
																<span>TBC</span>
															</a>
															<a className="dropdown-item eachCrypto" onClick={() => { setActiveTop("ETH"); getBalance("ETH"); getPriceMap("ETH") }} href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Bitcoin" />
																<span>ETH</span>
															</a>
															<a className="dropdown-item eachCrypto" onClick={() => { setActiveTop("BTC"); getBalance("BTC"); getPriceMap("BTC") }} href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
																<span>BTC</span>
															</a>
															<a className="dropdown-item eachCrypto" onClick={() => { setActiveTop("USDT"); getBalance("USDT"); getPriceMap("USDT") }} href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
																<span>USDT</span>
															</a>
														</div>
													</div>
													<input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} className="border-0 text-center t03 form-control" placeholder="Enter Amount" required />
												</div>
												<div className="separator" />
												<div className="eachRow">
													<div className="numInRupees">
														<strong className="fw600">~ $ {Number(price1)}</strong>
													</div>
													<div className="fw500 sumUpCell">Balance: {formatDecimal(fromWei(balance))}</div>
												</div>
											</div>
											<button className="btn border-0 reverseBtn">
												<img src="/assets/images/iconSvgs/reverseArrows.svg" alt="" />
											</button>
											<div className="doubleStackRow">
												<div className="eachRow">
													<div className="dropdown">
														<button className="btn border-0 dropdown-toggle" href="#" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
															<div className="eachCrypto">
																<img src="/assets/images/CryptoTBW.svg" alt="TBW" />
																<span className="fw500">{activeBottom}</span>
															</div>
															<i data-feather="chevron-down" />
														</button>
														<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
															<a onClick={() => { setActiveBottom("TBC"); getBalanceSec("TBC"); getPriceMap(activeTop, "TBC") }} className="dropdown-item eachCrypto" href="#">
																<img src="/assets/images/CryptoTBW.svg" alt="BUSD" />
																<span>TBC</span>
															</a>
															<a onClick={() => { setActiveBottom("ETH"); getBalanceSec("ETH"); getPriceMap(activeTop, "ETH") }} className="dropdown-item eachCrypto" href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Bitcoin" />
																<span>ETH</span>
															</a>
															<a onClick={() => { setActiveBottom("BTC"); getBalanceSec("BTC"); getPriceMap(activeTop, "BTC") }} className="dropdown-item eachCrypto" href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
																<span>BTC</span>
															</a>
															<a onClick={() => { setActiveBottom("USDT"); getBalanceSec("USDT"); getPriceMap(activeTop, "USDT") }} className="dropdown-item eachCrypto" href="#">
																<img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
																<span>USDT</span>
															</a>
														</div>
													</div>
													<input type="text" className="border-0 fw600 text-center t03 form-control fontChange" placeholder="SWAP TO" disabled />
												</div>
												<div className="separator" />
												<div className="eachRow">
													<div className="numInRupees">
														<strong className="fw600">~ $ {Number(price2)}</strong>
													</div>
													<div className="fw500 sumUpCell">Balance: {formatDecimal(fromWei(balance1))}</div>
												</div>
											</div>
											{/* <div className="conversionDetails">
												<div className="eachRow">
													<h2 className="fw600 mb-0">10 TBW = 1 BUSD</h2>
													<button className="btn ddArrowBtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDetails" aria-expanded="false" aria-controls="collapseDetails">
														<i data-feather="chevron-down" />
													</button>
												</div>
												<div className="collapse" id="collapseDetails">
													<div className="separator" />
													<ul className="list-unstyled pairValues">
														<li>
															<div className="fw600 pair">
																<div className="labelText">Liquidity provider fee</div>
																<div className="value">0.008 BUSD</div>
															</div>
														</li>
														<li>
															<div className="fw600 pair">
																<div className="labelText">
																	Route <img src="/assets/images/iconSvgs/Question.svg" alt="" />
																</div>
																<div className="value">BUSD TBW &gt; (O.008%)</div>
															</div>
														</li>
														<li>
															<div className="fw600 pair">
																<div className="labelText">
																	Price impact <img src="/assets/images/iconSvgs/Question.svg" alt="" />
																</div>
																<div className="value">BUSD TBW &gt; (O.008%)</div>
															</div>
														</li>
														<li>
															<div className="fw600 pair">
																<div className="labelText">
																	Minimum recieved <img src="/assets/images/iconSvgs/Question.svg" alt="" />
																</div>
																<div className="value">0.1229.1 BUSD</div>
															</div>
														</li>
														<li>
															<div className="fw600 pair">
																<div className="labelText">
																	Slippage tolerence <img src="/assets/images/iconSvgs/Question.svg" alt="" />
																</div>
																<div className="value">0.20%</div>
															</div>
														</li>
													</ul>
												</div>
											</div> */}
										</div>
										<div className="btnGroup">
											{approve ? (
												<button onClick={onApprove} type="button" className="fw500 t04 w-100 btn themeBtn">
													Approve
												</button>
											) : (
												<button onClick={onSwap} type="button" className="fw500 t04 w-100 btn themeBtn">
													Swap
												</button>
											)}
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="pills-info" role="tabpanel" aria-labelledby="pills-info-tab">
									<form className="w-100 pt-4 tabPillInnerWrap">
										<div className="stacks">
											<div className="infoDetails">
												<ul className="nav nav-pills nav-justified innerTabPills" id="pills-tab" role="tablist">
													<li className="nav-item" role="presentation">
														<button className="nav-link active" id="pills-BUSD-tab" data-bs-toggle="pill" data-bs-target="#pills-BUSD" type="button" role="tab" aria-controls="pills-BUSD" aria-selected="true">BUSD</button>
													</li>
													<li className="nav-item" role="presentation">
														<button className="nav-link" id="pills-TBW-tab" data-bs-toggle="pill" data-bs-target="#pills-TBW" type="button" role="tab" aria-controls="pills-TBW" aria-selected="false">TBW</button>
													</li>
												</ul>
												<div className="tab-content" id="pills-tabContent">
													<div className="tab-pane fade show active" id="pills-BUSD" role="tabpanel" aria-labelledby="pills-BUSD-tab">
														<div className="conversionDetails">
															<ul className="list-unstyled my-2 pairValues">
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Price</div>
																		<div className="value">₹ 78.20</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Trading Volume (24H)</div>
																		<div className="value">₹ 1,312,213</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Market Cap Rank</div>
																		<div className="value">7</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Market Cap</div>
																		<div className="value">₹ 26,135,232,3423.78</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">All-Time High</div>
																		<div className="value">₹ 260.06 -70%</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">All-Time Low</div>
																		<div className="value">₹ 0,5052 15334.34%</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Circulating Supply</div>
																		<div className="value">335,187,327 BUSD</div>
																	</div>
																</li>
																<li>
																	<div className="fw600 pair">
																		<div className="labelText">Total Supply</div>
																		<div className="value">335,187,327 BUSD</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
													<div className="tab-pane fade" id="pills-TBW" role="tabpanel" aria-labelledby="pills-TBW-tab">
														<div className="mt-4">
															<div className="conversionDetails">
																<ul className="list-unstyled my-2 pairValues">
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Price</div>
																			<div className="value">₹ 67.20</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Trading Volume (24H)</div>
																			<div className="value">₹ 2,783,900</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Market Cap Rank</div>
																			<div className="value">80</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Market Cap</div>
																			<div className="value">₹ 12,565,233,4343.78</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">All-Time High</div>
																			<div className="value">₹ 323.06 -89%</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">All-Time Low</div>
																			<div className="value">₹ 0.3232 15334.34%</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Circulating Supply</div>
																			<div className="value">983,232,545 BUSD</div>
																		</div>
																	</li>
																	<li>
																		<div className="fw600 pair">
																			<div className="labelText">Total Supply</div>
																			<div className="value">121,542,455 BUSD</div>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	)
}

export default Swap