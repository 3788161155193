import React from 'react'
import Header from '../components/Header'

function Liquidity() {
    return (
        <div className="w-100 dashboardCover">
            <Header active="liquidity" />

            <main className="w-100 mainContent liqSwapWrap">
                <div className="container">
                    <div className="staticModalBox">
                        <div className="bgWrapper">
                            <div className="titleRowMain">
                                <div className="titleText">
                                    <img src="/assets/images/liquidityLogo.svg" alt="Liquidity Logo" />
                                    <h1 className="fw600 m-0">Add Liquidity</h1>
                                </div>
                                <button className="btn filterBtn">
                                    <img src="/assets/images/iconSvgs/Filter.svg" alt="" />
                                </button>
                            </div>
                            <div className="customLiqTabs">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="text-uppercase nav-link active" id="pills-busd-tab" data-bs-toggle="pill" data-bs-target="#pills-busd" type="button" role="tab" aria-controls="pills-busd" aria-selected="false">BUSD</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="text-uppercase nav-link" id="pills-tbw-tab" data-bs-toggle="pill" data-bs-target="#pills-tbw" type="button" role="tab" aria-controls="pills-tbw" aria-selected="false">TBW</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-busd" role="tabpanel" aria-labelledby="pills-busd-tab">
                                    <form className="w-100 tabPillInnerWrap">
                                        <div className="ddPwdSet">
                                            <div className="btn-group ddPwdGroup">
                                                <div className="eachCrypto">
                                                    <img src="/assets/images/CryptoBUSD.svg" alt="BUSD" />
                                                    <span className="fw500">BUSD</span>
                                                </div>
                                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split ms-auto ddArrowBtn" data-toggle="dropdown" id="ddArrowBtn" aria-haspopup="true" aria-expanded="false">
                                                    <i data-feather="chevron-down" />
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="ddArrowBtn">
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoTBW.svg" alt="BUSD" />
                                                        <span>TBW</span>
                                                    </a>
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoBUSD.svg" alt="Bitcoin" />
                                                        <span>Bitcoin</span>
                                                    </a>
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
                                                        <span>Etherum</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="btn-group ddPwdGroup">
                                                <div className="eachCrypto">
                                                    <img src="/assets/images/CryptoTBW.svg" alt="TBW" />
                                                    <span className="fw500">TBW</span>
                                                </div>
                                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split ms-auto ddArrowBtn" data-toggle="dropdown" id="ddArrowBtn" aria-haspopup="true" aria-expanded="false">
                                                    <i data-feather="chevron-down" />
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="ddArrowBtn">
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoBUSD.svg" alt="BUSD" />
                                                        <span>BUSD</span>
                                                    </a>
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoBUSD.svg" alt="Bitcoin" />
                                                        <span>Bitcoin</span>
                                                    </a>
                                                    <a className="dropdown-item eachCrypto" href="#">
                                                        <img src="/assets/images/CryptoBUSD.svg" alt="Etherum" />
                                                        <span>Etherum</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="ddPwdGroup pwdWrap">
                                                <input type="text" name id="showLiq" defaultValue="0.008 % fee tier" readOnly />
                                                <button className="btn ddArrowBtn hideShowPwd" id="showPwdBtn" name="showBtn">
                                                    <img src="/assets/images/iconSvgs/Hide.svg" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                        <ul className="w-100 list-inline durationList">
                                            <li>
                                                <div className="radioCover">
                                                    <input type="radio" name="duration" id="duration24" />
                                                    <label htmlFor="duration24" className="mb-0 t04 itemToCheck">
                                                        <div className="fw700 num">0.002%</div>
                                                        <div className="fw600 text">Best for all the pairs</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="radioCover">
                                                    <input type="radio" defaultChecked name="duration" id="duration30" />
                                                    <label htmlFor="duration30" className="mb-0 t04 itemToCheck">
                                                        <div className="fw700 num">0.002%</div>
                                                        <div className="fw600 text">Best for all the pairs</div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="radioCover">
                                                    <input type="radio" name="duration" id="duration36" />
                                                    <label htmlFor="duration36" className="mb-0 t04 itemToCheck">
                                                        <div className="fw700 num">0.002%</div>
                                                        <div className="fw600 text">Best for all the pairs</div>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="fieldSet">
                                            <div className="bg-white form-group">
                                                <label htmlFor className="fw500 mb-0 label">BUSD Amount</label>
                                                <input type="text" className="border-0 t03 form-control" placeholder="24%" />
                                            </div>
                                            <div className="bg-white form-group">
                                                <label htmlFor className="fw500 mb-0 label">TBW Amount</label>
                                                <input type="text" className="border-0 t03 form-control" placeholder="0.00 - ₹ 0.00" />
                                            </div>
                                        </div>
                                        <div className="btnGroup">
                                            <button type="button" className="fw500 t04 w-100 btn themeBtn">Add
                                                Liquidity</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="pills-tbw" role="tabpanel" aria-labelledby="pills-tbw-tab">
                                    <form className="w-100 tabPillInnerWrap">
                                        Other Tab content
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    )
}

export default Liquidity