import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useAccount, useContract, useSigner } from 'wagmi';
import Web3 from 'web3';
import Header from '../components/Header'
import { APP_DATA, formatDecimal, fromWei, getContract, unixToDate } from '../utils/constant';
import MyLoading from '../components/MyLoading';

function StackingFD() {
	const [months, setMonths] = useState("");
	const [apy, setApy] = useState("");
	const [amount, setAmount] = useState("");
	const [active, setActive] = useState("");
	const [fixedDepositHistory, setFixedDepositHistory] = useState([]);
	const [historyDateToggles, setHistoryDateToggles] = useState([]);
	const [withdrawalModal, setWithdrawalModal] = useState(false);
	const [activeItem, setActiveItem] = useState("");

	const [loadCompleted, setLoadCompleted] = useState(false);

	const { address } = useAccount()
	const account = address;
	const { data: signer } = useSigner()
	const contract = useContract({ address: APP_DATA.contractAddress, abi: APP_DATA.abi, signerOrProvider: signer })
	const contractUSDT = useContract({ address: APP_DATA.contractAddressUsdt, abi: APP_DATA.abi_usdt, signerOrProvider: signer })
	const contractWBLOOM = useContract({ address: APP_DATA.contractAddressWBloom, abi: APP_DATA.abi_wBloom, signerOrProvider: signer })
	const [loading, setLoading] = useState(true);

	const onSubmit = (e) => {
		e && e.preventDefault();
		const mAmount = new Web3(Web3.givenProvider).utils.toWei(amount);
		contract.LockItem(months, mAmount).then(res => {
			res.wait().then(() => {
				window.location.reload();
			})
		})
	}

	const onMonthsChange = async (value) => {
		setMonths(value);
		const apy = await contract.ApyLock(value);
		setApy(apy);
	}

	const onAmountChange = (e) => {
		setAmount(e.target.value);
	}

	const onApprove = () => {
		// setLoading(true);
		const mAmount = `1` + '0'.repeat(71);
		contractUSDT.approve(APP_DATA.contractAddress, mAmount).then(res => {
			res.wait().then(() => {
				window.location.reload();
				setLoading(false);
			}).catch(() => {
				setLoading(false);
			});
		})
	}

	const getFixedDepositHistory = async () => {
		setLoading(true);
		const res = await contract.listMyFDID();
		const res1 = [];
		console.log(res);
		if (res.length <= 0) {
			setLoadCompleted(true);
			setLoading(false);
		}
		res.forEach(async (ele, i) => {
			const earning = await contract.getlockidearning(ele.id, address)
			res1.push({ ...ele, earning });
			if (i === res.length - 1) {
				setFixedDepositHistory(res1);
				setLoadCompleted(true);
				setLoading(false);
			}
		})
		const arr = Array(res.length).fill(false);
		setHistoryDateToggles(arr);
	}

	const withdrawalAmount = async (id) => {
		const allowance = await contractWBLOOM.allowance(account, APP_DATA.contractAddress);
		const mAmount = `1` + '0'.repeat(71);

		if (Number(allowance) === 0) {
			contractWBLOOM.approve(APP_DATA.contractAddress, mAmount).then(res => {
				res.wait().then(() => {
					window.location.reload();
				})
			})
		} else {
			contract.WithdrawLock(id).then(res => {
				res.wait().then(() => {
					window.location.reload();
				})
			})
		}
	}

	const withdrawalEarning = (id) => {
		contract.withdrawLockearning(id).then(res => {
			res.wait().then(res => {
				window.location.reload();
			});
		})
	}

	useEffect(() => {
		if (signer) {
			getFixedDepositHistory();
			contractUSDT.allowance(account, APP_DATA.contractAddress).then(allowance => {
				if (Number(allowance) === 0) {
					setActive("approve")
				} else {
					setActive("submit");
				}
			})
		}
	}, [signer])

	useEffect(() => {
		if (!document.getElementById("dataTablesScript") && loadCompleted) {
			const script = document.createElement("script");
			script.id = "dataTablesScript";
			script.innerHTML = `
				$('table.display').DataTable({
					scrollX: true,
					language: {
						paginate: {
							next: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>',
							previous: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>'
						}
					},
					"initComplete": function (settings, json) {

					}
				});`;
			script.async = true;
			document.body.appendChild(script);
		}
	}, [loadCompleted]);

	return (
		<div className="w-100 dashboardCover concentricCircles">
			<Header active="stackingFD" />

			{loading && <MyLoading />}

			<main className="w-100 mainContent">
				<div className="container">
					<div className="stackWrap">
						<div className="tabbingBox">
							<nav>
								<ul className="list-inline">
									<li>
										<a href="/stackingFD" className={`navLinkStack fw600 t04 activeStack`}>
											<svg width="34" height="34" viewBox="0 0 34 34" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M25.5565 19.1959C24.9615 19.7767 24.6215 20.6126 24.7065 21.5051C24.834 23.0351 26.2365 24.1542 27.7665 24.1542H30.4582V25.8401C30.4582 28.7726 28.064 31.1667 25.1315 31.1667H10.809C11.2482 30.7984 11.6307 30.3451 11.9282 29.8351C12.4523 28.9851 12.7498 27.9792 12.7498 26.9167C12.7498 23.7859 10.214 21.2501 7.08317 21.2501C5.7515 21.2501 4.519 21.7176 3.5415 22.4968V16.3059C3.5415 13.3734 5.93566 10.9792 8.86816 10.9792H25.1315C28.064 10.9792 30.4582 13.3734 30.4582 16.3059V18.3459H27.5965C26.8032 18.3459 26.0807 18.6576 25.5565 19.1959Z"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path
													d="M3.5415 17.5808V11.1068C3.5415 9.42092 4.57567 7.91919 6.14817 7.32419L17.3965 3.07419C19.1532 2.40836 21.0373 3.71173 21.0373 5.5959V10.9792"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path
													d="M31.9581 19.7911V22.7095C31.9581 23.4887 31.3348 24.1262 30.5415 24.1545H27.7648C26.2348 24.1545 24.8323 23.0353 24.7048 21.5053C24.6198 20.6128 24.9598 19.777 25.5548 19.1962C26.079 18.6578 26.8015 18.3462 27.5948 18.3462H30.5415C31.3348 18.3745 31.9581 19.012 31.9581 19.7911Z"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path d="M9.9165 17H19.8332" strokeWidth="2" strokeLinecap="round"
													strokeLinejoin="round" />
												<path
													d="M12.7498 26.9167C12.7498 27.9792 12.4523 28.985 11.9282 29.835C11.6307 30.345 11.2482 30.7983 10.809 31.1667C9.81734 32.0592 8.514 32.5833 7.08317 32.5833C5.01484 32.5833 3.21567 31.4783 2.23817 29.835C1.71401 28.985 1.4165 27.9792 1.4165 26.9167C1.4165 25.1317 2.23817 23.5308 3.5415 22.4967C4.519 21.7175 5.7515 21.25 7.08317 21.25C10.214 21.25 12.7498 23.7858 12.7498 26.9167Z"
													strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
													strokeLinejoin="round" />
												<path d="M4.875 26.9159L6.27749 28.3184L9.29499 25.5276" strokeWidth="2"
													strokeLinecap="round" strokeLinejoin="round" />
											</svg>
											<span>Fixed Deposits</span>
										</a>
									</li>
									<li>
										<a href="/stackingRD" className={`navLinkStack fw600 t04 `}>
											<svg width="34" height="34" viewBox="0 0 34 34" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M2.8335 12.0417H20.5418" strokeWidth="2" strokeMiterlimit="10"
													strokeLinecap="round" strokeLinejoin="round" />
												<path d="M8.5 23.375H11.3333" strokeWidth="2" strokeMiterlimit="10"
													strokeLinecap="round" strokeLinejoin="round" />
												<path d="M14.875 23.375H20.5417" strokeWidth="2" strokeMiterlimit="10"
													strokeLinecap="round" strokeLinejoin="round" />
												<path
													d="M31.1668 19.8758V22.8224C31.1668 27.7949 29.906 29.0416 24.8768 29.0416H9.1235C4.09433 29.0416 2.8335 27.7949 2.8335 22.8224V11.1774C2.8335 6.20492 4.09433 4.95825 9.1235 4.95825H20.5418"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path d="M28.3335 4.95825V13.4583L31.1668 10.6249" strokeWidth="2"
													strokeLinecap="round" strokeLinejoin="round" />
												<path d="M28.3333 13.4583L25.5 10.625" strokeWidth="2"
													strokeLinecap="round" strokeLinejoin="round" />
											</svg>
											<span>Recurring Deposits</span>
										</a>
									</li>
									<li>
										<a href="/stackingDep" className={`navLinkStack fw600 t04`}>
											<svg width="35" height="34" viewBox="0 0 35 34" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M12.0303 16.15C12.0303 17.2408 12.8803 18.1333 13.9144 18.1333H16.0394C16.9461 18.1333 17.6828 17.3541 17.6828 16.405C17.6828 15.3708 17.2294 15.0025 16.5636 14.7616L13.1636 13.5716C12.4836 13.3308 12.0303 12.9625 12.0303 11.9283C12.0303 10.9791 12.7669 10.2 13.6736 10.2H15.7986C16.8469 10.2141 17.6969 11.0925 17.6969 12.1833"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path d="M14.8633 18.2041V19.2524" strokeWidth="2" strokeLinecap="round"
													strokeLinejoin="round" />
												<path d="M14.8633 9.08081V10.1858" strokeWidth="2" strokeLinecap="round"
													strokeLinejoin="round" />
												<path
													d="M14.8494 25.4716C21.1008 25.4716 26.1686 20.4038 26.1686 14.1524C26.1686 7.90102 21.1008 2.83325 14.8494 2.83325C8.59804 2.83325 3.53027 7.90102 3.53027 14.1524C3.53027 20.4038 8.59804 25.4716 14.8494 25.4716Z"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
												<path
													d="M19.085 28.1634C20.36 29.9626 22.4425 31.1384 24.8225 31.1384C28.69 31.1384 31.835 27.9934 31.835 24.1259C31.835 21.7742 30.6733 19.6917 28.9025 18.4167"
													strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
											<span>Deposits</span>
										</a>
									</li>
								</ul>
							</nav>

							<div className="tabInner w-100 fdTabContent">
								<form method='post' onSubmit={onSubmit}>
									<ul className="w-100 mb-0 list-inline durationList">
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("6") }}>
												<input type="radio" onChange={() => { }} checked={months === '6' ? true : false} name="duration" id="duration06" />
												<label htmlFor="duration06" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">06</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("12") }}>
												<input type="radio" onChange={() => { }} checked={months === '12' ? true : false} name="duration" id="duration12" />
												<label htmlFor="duration12" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">12</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("18") }}>
												<input type="radio" onChange={() => { }} checked={months === '18' ? true : false} name="duration" id="duration18" />
												<label htmlFor="duration18" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">18</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("24") }}>
												<input type="radio" onChange={() => { }} checked={months === '24' ? true : false} name="duration" id="duration24" />
												<label htmlFor="duration24" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">24</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("30") }}>
												<input type="radio" onChange={() => { }} checked={months === '30' ? true : false} name="duration" id="duration30" />
												<label htmlFor="duration30" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">30</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
										<li>
											<div className="radioCover" onClick={() => { onMonthsChange("36") }}>
												<input type="radio" onChange={() => { }} checked={months === '36' ? true : false} name="duration" id="duration36" />
												<label htmlFor="duration36" className="mb-0 t04 itemToCheck">
													<div className="fw700 num">36</div>
													<div className="fw600 text">Months</div>
												</label>
											</div>
										</li>
									</ul>
									<div className="fieldSet">
										<div className="bg-white form-group">
											<label htmlFor="" className="fw500 label">APY</label>
											<input type="text" className="border-0 t03 form-control form-control-smaller" value={apy + "%"} disabled />
										</div>
										<div className="bg-white form-group">
											<label htmlFor="" className="fw500 label">Amount</label>
											<input type="text"
												value={amount}
												onChange={onAmountChange}
												className="border-0 t03 form-control form-control-smaller"
												placeholder="0.00 - ₹ 0.00" />
										</div>
									</div>
									<div className="mt-3 pt-1 btnGroup">
										{
											active === "submit" ?
												<button type="button" onClick={onSubmit} className="fw500 themeBtn btn">Submit</button>
												:
												<button type="button" onClick={onApprove} className="fw500 themeBtn btn">Approve</button>
										}
									</div>
								</form>
							</div>

						</div>
						<div className="gridBox">
							<div className="w-100 mt-0 mainTitleDT">
								<h2 className="fw600 mb-0">Transaction History</h2>
								<ul className="list-inline mb-0">
									<li>
										<form role="search">
											<input className="form-control t06" id="searchField2" type="search"
												placeholder="Search" aria-label="Search" />
											<button className="border-0 btnSearchNav position-relative btn t06" id="btnDTSearch"
												type="submit">
												<span>
													<i data-feather="search"></i>
												</span>
											</button>
										</form>
									</li>
									<li>
										<button className="btn bg-white">
											<img src="/assets/images/iconSvgs/Filter.svg" alt="Filter icon" />
										</button>
									</li>
								</ul>
							</div>
							<div id="transactionWrapper" className="dataTables_wrapper table-responsive">
								<table id="transactionDT" className="w-100 border-0 display dataTable"
									aria-describedby="example_info">
									<thead>
										<tr>
											<th className="sorting sorting_asc" tabIndex="0" aria-controls="transactionDT"
												rowSpan="1" colSpan="1" aria-sort="ascending"
												aria-label="ID: activate to sort column descending">Sr. No.
											</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Price: activate to sort column ascending">
												Price</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Amount: activate to sort column ascending">
												Amount</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Date: activate to sort column ascending">
												Date
											</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Earning: activate to sort column ascending">
												Earning
											</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1"
												aria-label="Earning Withdraw: activate to sort column ascending">
												Earning<br />Withdraw
											</th>
											<th className="sorting text-nowrap" tabIndex="0" aria-controls="transactionDT"
												rowSpan="1" colSpan="1"
												aria-label="Amt Withdraw Date: activate to sort column ascending">
												Amt Withdraw<br />Date
											</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Action: activate to sort column ascending">Action
											</th>
											<th className="sorting" tabIndex="0" aria-controls="transactionDT" rowSpan="1"
												colSpan="1" aria-label="Status: activate to sort column ascending">
												Status</th>
										</tr>
									</thead>
									<tbody>
										{fixedDepositHistory.map((ele, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>$ {parseFloat(ele.Price) / 100}</td>
												<td>$ {fromWei(ele.amount)}</td>
												<td>
													<div className="toggleSwitch">
														<input type="checkbox" style={{ width: "10px" }} onChange={() => { }} checked={historyDateToggles[i]} id="" />
														<div className="innerBadges">
															<div onClick={() => { let t = historyDateToggles; t[i] = false; setHistoryDateToggles(JSON.parse(JSON.stringify(t))); }} className="date t03 dateBlack">{unixToDate(ele.start_time).format("DD")}<span
																className="t03 text-nowrap">-{unixToDate(ele.start_time).format("MM-YYYY")}</span>
															</div>
															<div onClick={() => { let t = historyDateToggles; t[i] = true; setHistoryDateToggles(JSON.parse(JSON.stringify(t))); }} className="date t03 dateMustard">{unixToDate(ele.end_time).format("DD")}<span
																className="t03 text-nowrap">-{unixToDate(ele.end_time).format("MM-YYYY")}</span>
															</div>
														</div>
													</div>
												</td>
												<td>$ {formatDecimal(fromWei(ele.earning))}</td>
												<td>$ {formatDecimal(fromWei(ele.earningwithdraw))}</td>
												<td>
													<div className="badge fw400 dateBadge">{Number(ele.withdraw_time) === 0 ? "N/A" : unixToDate(ele.withdraw_time).format("DD-MM-YYYY")}</div>
												</td>
												<td className="text-center">
													<button className="btn actionBtn" onClick={() => { setWithdrawalModal(true); setActiveItem(ele); }}>
														<img src="/assets/images/iconSvgs/card-send.svg" alt="Action" />
													</button>
												</td>
												<td>
													{ele.complete === true ?
														<span className="tagStatus rejected">Complete</span>
														: <span className="tagStatus completed">Active</span>}
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</main>

			<Modal show={withdrawalModal} size="lg" style={{ padding: "0px", borderRadius: "15px" }}>
				<div className="modal-dialog modal-dialog-centered m-0">
					<div className="modal-content">
						<div className="p-0 modal-body">
							<div className="modalContent">
								<div className="bg-white imgLeft">
									<img src="/assets/images/buildings.svg" alt="Image Bg" />
								</div>
								<div className="contentRight">
									<button type="button" className="btn-close" onClick={() => setWithdrawalModal(false)}>
										<img src="/assets/images/iconSvgs/crossModal.svg" alt="" />
									</button>
									<div className="h-100 btnGroup">
										<button className="themeBtn btn border-0 w-100 fw500" onClick={() => withdrawalAmount(activeItem.id)}>
											<img src="/assets/images/iconSvgs/card-sendBig.svg" alt="" />
											<span>Withdrawal Amount</span>
										</button>
										<button className="themeBtn btn border-0 w-100 fw500" onClick={() => withdrawalEarning(activeItem.id)}>
											<img src="/assets/images/iconSvgs/card-receive.svg" alt="" />
											<span>Withdrawal Earning</span>
										</button>
										<div className="clickHere">
											<a href="" data-bs-toggle="tooltip" data-bs-placement="top"
												data-bs-custom-className="custom-tooltip"
												data-bs-title="This is a placeholder text used to demonstrate the visual form of a document">
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clip-rule="evenodd"
														d="M0.666992 8.99935C0.666992 4.40018 4.40033 0.666016 9.00033 0.666016C13.6087 0.666016 17.3337 4.40018 17.3337 8.99935C17.3337 13.6002 13.6087 17.3327 9.00033 17.3327C4.40033 17.3327 0.666992 13.6002 0.666992 8.99935ZM8.26699 5.84102C8.26699 5.44185 8.60033 5.10768 9.00033 5.10768C9.40033 5.10768 9.72533 5.44185 9.72533 5.84102V9.52435C9.72533 9.92518 9.40033 10.2493 9.00033 10.2493C8.60033 10.2493 8.26699 9.92518 8.26699 9.52435V5.84102ZM9.00866 12.9002C8.60033 12.9002 8.27533 12.5668 8.27533 12.1668C8.27533 11.7668 8.60033 11.4418 9.00033 11.4418C9.40866 11.4418 9.73366 11.7668 9.73366 12.1668C9.73366 12.5668 9.40866 12.9002 9.00866 12.9002Z"
														fill="#181818" />
												</svg>
											</a>
											<span>If you need any help</span> <a href="" className="fw700">click here</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div >
	)
}

export default StackingFD