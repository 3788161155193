import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useAccount, useContract, useSigner } from 'wagmi';
import Header from '../components/Header'
import MyLoading from '../components/MyLoading';
import TopChartView from '../components/TopChartView';
import { APP_DATA, createIPFSClient, formatAddr } from '../utils/constant';

function AddDao() {
  const { address } = useAccount();
  const { data: signer } = useSigner()
  const contractDAO = useContract({ address: APP_DATA.contractAddressDao, abi: APP_DATA.abi_dao, signerOrProvider: signer })
  const contractBLOOM = useContract({ address: APP_DATA.contractAddressBloom, abi: APP_DATA.abi_bloom, signerOrProvider: signer })
  const [proposals, setProposals] = useState([]);
  const [title, setTitle] = useState('');
  const [endTime, setEndTime] = useState(new Date());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (document.getElementById("script-ckeditor_id")) return;
    else {
      const script = document.createElement("script");
      script.src = "/assets/js/script-ckeditor.js";
      script.id = "script-ckeditor_id";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const addDataToIPFS = async (data) => {
    const ipfsClient = createIPFSClient();
    try {
      const added = await ipfsClient.add(JSON.stringify(data));
      const url = `${APP_DATA.ipfsURL}/${added.path}`;
      console.log(url);
      return url;
    } catch (error) {
      console.log('Error uploading file: ', error)
      return null;
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    const allowance = await contractBLOOM.allowance(address, APP_DATA.contractAddressDao);
    const mAmount = `1` + '0'.repeat(71);

    if (Number(allowance) === 0) {
      contractBLOOM.approve(APP_DATA.contractAddressDao, mAmount).then(res => {
        res.wait().then(receipt => {
          toast.success("Permission Added successfully.");
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          toast.error(error.reason);
        });
      })
    } else {
      const data = document.getElementsByClassName("ck-restricted-editing_mode_standard");
      if (data && data.length > 0) {
        const post = data[0].innerHTML.toString();
        const endTime1 = Math.floor(new Date(endTime).getTime() / 1000);

        if (title) {
          const url = await addDataToIPFS({ title: title, post: post });
          if (url) {
            const trans = contractDAO.CreateProposal(url, endTime1, { from: address });
            trans.then((res) => {
              res.wait().then((receipt) => {
                toast.success("Proposal created successfully.");
                window.location.href = "/dao";
                setLoading(false);
              });
            }).catch((err) => {
              toast.error(err.reason);
              setLoading(false);
            })
          } else {
            setLoading(false);
          }
        } else {
          toast.error("Please enter proposal title!!!");
          setLoading(false);
        }
      } else {
        toast.error("Please enter proposal!!!");
        setLoading(false);
      }
    }
  }

  const getAllProposals = async () => {
    setLoading(true);
    const totalProposal = await contractDAO.totalProposal();
    let prop = [];
    if (Number(totalProposal) === 0) {
      setLoading(false);
    }
    for (let i = 1; i <= Number(totalProposal); i++) {
      const proposal = await contractDAO.idToPost(i);
      const proposalData = await axios.get(proposal.Proposal_Url);
      const isVoted = await contractDAO.checkVotedvoter(i);

      const proposalDataJson = { ...proposal, post: proposalData.data, isVoted };
      prop.push(proposalDataJson);

      if (i === Number(totalProposal)) {
        setLoading(false);
        setProposals(prop);
      }
    }
  }

  useEffect(() => {
    if (signer) {
      getAllProposals();
    }
  }, [signer])

  const onChangeEndTime = (e) => {
    setEndTime(e.target.value);
  }

  const getDateDeff = (date2, date1) => {
    const Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  }

  return (
    <div className="w-100 dashboardCover">
      <Header active="dao" />
      {loading && <MyLoading />}
      <main className="w-100 mainContent">
        <div className="container">
          <TopChartView />
          <div className="div6040 rowReverseInSmall">
            <div className="div60">
              <div className="fieldSet mb-3">
                <label className='ms-2'><b>Proposal Title</b></label>
                <div className="bg-white br-2 px-3 py-2 overflow-hidden form-group">
                  <input type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="border-0 t03 form-control form-control-smaller"
                    placeholder="Enter Title" />
                </div>
              </div>
              <section className="ckeditorSection">
                <div id="editor" />
              </section>
              <label className="t03 ms-2 mt-3"><b>End Time (MM-DD-YYYY)</b></label>
              <div className="bg-white br-2 px-3 py-2 overflow-hidden form-group">
                <input type="datetime-local" value={endTime} onChange={onChangeEndTime} className='border-0 t03 form-control-smaller' />
              </div>
              <div className="mt-5 ckeditorSectionBottom">
                <button className="btn t04 btn--submit me-2" onClick={onSubmit}>Submit Proposal</button>
                <button className="btn t04 btn--cancel">Cancel</button>
              </div>
            </div>
            <div className="div40">
              <section className="daoSection recentPropSection">
                <h2 className="mb-2">Recent Proposals</h2>
                <div className="recentPropSection__inner">
                  {!loading && proposals.length === 0 &&
                    <div className="text-center mt-2">
                      <h3>No Proposals</h3>
                    </div>}
                  {proposals.map((item, index) => (
                    <div className="recentPropSection__item">
                      <h4>Proposal by {formatAddr(item.creator)}</h4>
                      <h3>{item?.post?.title ? item?.post?.title : "Croswap Structural Proposals [ Test ]"}</h3>
                      <div className="row">
                        <div className="col-6 recentPropSection__endTime">
                          Ending in {getDateDeff(new Date(Number(item.endtime) * 1000), new Date())} days
                        </div>
                        <div className="col-6 text-end recentPropSection__state active">
                          <span className><span className="dot" /> <span>Active</span></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>

      </main >
    </div >
  )
}

export default AddDao