import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useAccount, useContract, useSigner } from 'wagmi';
import { fromWei, formatDecimal, APP_DATA } from '../utils/constant';
import MyLoading from "./MyLoading";

export default function TopChartView() {
  const { address } = useAccount()
  const account = address;
  const { data: signer } = useSigner()
  const contract = useContract({ address: APP_DATA.contractAddress, abi: APP_DATA.abi, signerOrProvider: signer })
  const contractWBLOOM = useContract({ address: APP_DATA.contractAddressWBloom, abi: APP_DATA.abi_wBloom, signerOrProvider: signer })
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userData, setUserData] = useState({});

  const [prices, setPrices] = useState({
    btc: '',
    bnb: '',
    bw: '',
    eth: '',
  })

  const [totalBalance, setTotalBalance] = useState([]);

  const [earnings, setEarnings] = useState({
    rd: 0, fd: 0, d: 0
  })

  const [earningWithdraws, setEarningWithdraws] = useState({
    rd: 0, fd: 0, d: 0
  })

  const [totalIds, setTotalIds] = useState(0);

  const getPrices = async () => {
    let bw = await contract.geteverything();
    bw = parseFloat(bw[2]) / 100;
    const btc = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=USD");
    const eth = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD");
    const bnb = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=USD");
    setPrices({
      ...prices,
      btc: btc.data.bitcoin.usd,
      bw,
      eth: eth.data.ethereum.usd,
      bnb: bnb.data.binancecoin.usd
    });
  }

  const getEarnings = async () => {
    const res = await contract.totalfdearning();
    const res1 = await contract.totalRDearning();
    const res2 = await contract.totalDepositeearning();
    setEarnings({ ...earnings, fd: fromWei(res), rd: fromWei(res1), d: fromWei(res2) });
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/script.js";
    script.async = true;
    const script1 = document.createElement("script");
    script1.src = "/assets/js/script-charts.js";
    script1.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script1);
  }, []);

  useEffect(() => {
    if (signer) {
      getPrices();
      getEarnings();

      contract.getuplineearning(address).then((res) => {
        setUserData(res);
      });
      contract.gettotalamount(address).then((res) => {
        setTotalBalance(res);
      })
    }
  }, [signer])

  return (
    <div className="topRow">
      {(loading || loading1 || loading2) && <MyLoading />}
      <ul className="list-unstyled smallBlockList">
        <li>
          <div className="t06 sBlock bitcoin">
            <div className="t04 thumbImg">
              <svg width="15" height="19" viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.1642 7.4369C14.4467 5.54537 13.0065 4.52853 11.0372 3.85019L11.676 1.28787L10.1159 0.899169L9.49398 3.39396C9.0844 3.29179 8.66327 3.19539 8.24481 3.09988L8.87117 0.588652L7.31237 0.199951L6.67312 2.76138C6.33373 2.68409 6.00056 2.60768 5.67716 2.52727L5.67894 2.51928L3.52797 1.9822L3.11306 3.64806C3.11306 3.64806 4.27028 3.91327 4.24585 3.92971C4.87754 4.08741 4.99216 4.50543 4.97261 4.83682L4.24496 7.75586C4.2885 7.76696 4.34491 7.78296 4.4071 7.80783L4.24274 7.76696L3.22234 11.8561C3.14505 12.048 2.94914 12.3359 2.50758 12.2266C2.52313 12.2492 1.3739 11.9436 1.3739 11.9436L0.599609 13.7294L2.62974 14.2354C2.8589 14.2928 3.08527 14.3519 3.30916 14.4103L3.30928 14.4103C3.45427 14.4481 3.59822 14.4857 3.7412 14.5224L3.09574 17.1145L4.65365 17.5032L5.29334 14.9391C5.71847 15.0546 6.13161 15.1612 6.53585 15.2616L5.89883 17.8137L7.45852 18.2024L8.10399 15.6156C10.7636 16.1189 12.764 15.9159 13.6049 13.5109C14.2832 11.574 13.5716 10.4568 12.1722 9.7278C13.1913 9.49191 13.9589 8.82157 14.1637 7.4369H14.1642ZM10.5987 12.4336C10.1559 14.2113 7.37285 13.4758 6.10059 13.1395C5.98666 13.1094 5.88485 13.0825 5.79791 13.0609L6.65439 9.62745C6.76064 9.65397 6.89046 9.6831 7.03752 9.7161C8.35371 10.0115 11.0511 10.6167 10.5992 12.4336H10.5987ZM7.30179 8.12319C8.36336 8.4064 10.6773 9.02372 11.0804 7.40886C11.4925 5.75681 9.24245 5.25887 8.14354 5.01569C8.02 4.98835 7.91101 4.96423 7.82199 4.94206L7.04547 8.05611C7.11895 8.07441 7.20518 8.09742 7.30179 8.12319Z"
                  fill="" />
              </svg>
            </div>
            <div className="briefInfo">
              <span className="labelText">Bitcoin (BTC)</span>
              <span className="fw700 value">${prices.btc}</span>
            </div>
            <img src="/assets/images/iconSvgs/bitcoinBg.svg" alt="" className="t04 bgIcon" />
          </div>
        </li>
        <li>
          <div className="t06 sBlock betaWorld">
            <div className="t04 thumbImg">
              <svg width="16" height="22" viewBox="0 0 16 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.06316 6.04092V9.81824L9.34127 11.1337V1.9023L7.132 0.626694L7.06316 0.581177V3.81572C3.47928 4.35395 0.729492 7.44739 0.729492 11.1809C0.729492 14.4462 2.83237 17.2216 5.75682 18.2269L5.7682 15.8561C4.07612 14.9821 2.91885 13.2161 2.91885 11.1809C2.91942 8.65984 4.69458 6.55241 7.06316 6.04092Z" />
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.6474 4.08826L10.636 6.45911C12.3281 7.33304 13.4853 9.09909 13.4853 11.1343C13.4853 13.6553 11.7096 15.7627 9.34159 16.2742V12.4969L7.06348 11.1815V20.4129L9.27275 21.6885L9.34159 21.734V18.4994C12.9255 17.9612 15.6753 14.8678 15.6753 11.1343C15.6747 7.869 13.5718 5.09361 10.6474 4.08826Z" />
              </svg>
            </div>
            <div className="briefInfo">
              <span className="labelText">Beta World (BW)</span>
              <span className="fw700 value">${prices.bw}</span>
            </div>
            <img src="/assets/images/iconSvgs/betaWorldBg.svg" alt="" className="t04 bgIcon" />
          </div>
        </li>
        <li>
          <div className="t06 sBlock etherium">
            <div className="t04 thumbImg">
              <img src="/assets/images/etheriumLogo.svg" alt="" />
            </div>
            <div className="briefInfo">
              <span className="labelText">Etherium (ETC)</span>
              <span className="fw700 value">${prices.eth}</span>
            </div>
            <img src="/assets/images/iconSvgs/etheriumBg.svg" alt="" className="t04 bgIcon" />
          </div>
        </li>
        <li>
          <div className="t06 sBlock binance">
            <div className="t04 thumbImg">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.27242 8.46763L10.0015 4.74016L13.7322 8.47067L15.9008 6.3006L10.0015 0.400024L4.10229 6.29908L6.27242 8.46763ZM0.399902 10.0003L2.56924 7.83102L4.73858 10.0003L2.56924 12.1696L0.399902 10.0003ZM10.002 15.2614L6.27285 11.5324L4.09968 13.6995L4.10271 13.7025L10.002 19.6L15.9012 13.6995L15.9027 13.6979L13.7326 11.5309L10.002 15.2614ZM15.2608 10.0009L17.4302 7.83167L19.5995 10.0009L17.4302 12.1702L15.2608 10.0009ZM10.0021 7.7974L12.2025 9.99931H12.2041L12.2025 10.0008L10.0021 12.2027L7.80162 10.0039L7.79858 9.99931L7.80162 9.99628L8.18681 9.6111L8.37486 9.42457L10.0021 7.7974Z"
                  fill="" />
              </svg>
            </div>
            <div className="briefInfo">
              <span className="labelText">Binance (BNB)</span>
              <span className="fw700 value">${prices.bnb}</span>
            </div>
            <img src="/assets/images/iconSvgs/binanceBg.svg" alt="" className="t04 bgIcon" />
          </div>
        </li>
      </ul>
      <ul className="list-unstyled largeBlockList">
        <li>
          <div className="w-100% t04 lgBlock tEarning">
            <div className="upperContent">
              <div className="t05 thumbImg">
                <svg className="t03" width="30" height="28" viewBox="0 0 30 28" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.655 17.394H22.9199C20.8141 17.3927 19.1072 15.6872 19.106 13.5814C19.106 11.4756 20.8141 9.77003 22.9199 9.76874H28.655"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M23.5685 13.4941H23.1269" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path fillRule="evenodd" clipRule="evenodd"
                    d="M8.9757 1.25H21.2205C25.3263 1.25 28.6547 4.57847 28.6547 8.68419V18.8517C28.6547 22.9574 25.3263 26.2858 21.2205 26.2858H8.9757C4.86998 26.2858 1.5415 22.9574 1.5415 18.8517V8.68419C1.5415 4.57847 4.86998 1.25 8.9757 1.25Z"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.9668 7.67906H15.6153" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                </svg>
                <span className="rounded-circle t03 lockIcon lockOpen">
                  <svg className="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z" />
                    <path d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z" />
                  </svg>
                </span>
              </div>
              <h2 className="mb-0 boxTitle">Fixed Deposite</h2>
            </div>
            <div className="sparkline">
              <canvas id="totalEarning"></canvas>
            </div>
            <div className="bottomContent">
              <ul className="list-inline lh1 mb-0">
                <li>
                  <div className="me-auto monthEarning">
                    <div className="lh1 labelText">Earning</div>
                    <div className="fw600 value">${formatDecimal(earnings.fd)}</div>
                  </div>
                </li>
                <li className="partition"></li>
                <li>
                  <div className="ms-auto monthEarning">
                    <div className="lh1 labelText">Withdraw</div>
                    <div className="fw600 value">${fromWei(userData?.user?.withdrawLock)}</div>
                  </div>
                </li>
              </ul>
              <div className="totalValue">
                <div className="lh1 labelText">Total Fixed Deposite</div>
                <div className="fw600 value">${totalBalance.length > 0 && fromWei(totalBalance[0])}</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="w-100% t04 lgBlock tEarning">
            <div className="upperContent">
              <div className="t05 thumbImg">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3335 16.15C11.3335 17.2408 12.1835 18.1333 13.2177 18.1333H15.3427C16.2493 18.1333 16.986 17.3542 16.986 16.405C16.986 15.3708 16.5327 15.0025 15.8668 14.7617L12.4668 13.5717C11.7868 13.3308 11.3335 12.9625 11.3335 11.9283C11.3335 10.9792 12.0702 10.2 12.9768 10.2H15.1018C16.1502 10.2142 17.0002 11.0925 17.0002 12.1833"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.1665 18.2042V19.2525" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path d="M14.1665 9.08081V10.1858" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path
                    d="M14.1527 25.4716C20.4041 25.4716 25.4718 20.4039 25.4718 14.1525C25.4718 7.90108 20.4041 2.83331 14.1527 2.83331C7.90126 2.83331 2.8335 7.90108 2.8335 14.1525C2.8335 20.4039 7.90126 25.4716 14.1527 25.4716Z"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M18.3882 28.1634C19.6632 29.9625 21.7457 31.1384 24.1257 31.1384C27.9932 31.1384 31.1382 27.9934 31.1382 24.1259C31.1382 21.7742 29.9765 19.6917 28.2057 18.4167"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <span className="rounded-circle t03 lockIcon lockOpen">
                  <svg className="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"
                    />
                    <path
                      d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"
                    />
                  </svg>
                </span>
              </div>
              <h2 className="mb-0 boxTitle">Recurring Deposite</h2>
            </div>
            <div className="sparkline">
              <canvas id="betaToken"></canvas>
            </div>
            <div className="bottomContent">
              <ul className="list-inline lh1 mb-0">
                <li>
                  <div className="me-auto monthEarning">
                    <div className="lh1 labelText">Earning</div>
                    <div className="fw600 value">${formatDecimal(earnings.rd)}</div>
                  </div>
                </li>
                <li className="partition"></li>
                <li>
                  <div className="ms-auto monthEarning">
                    <div className="lh1 labelText">Withdraw</div>
                    <div className="fw600 value">${fromWei(userData?.user?.withdrawRD)}</div>
                  </div>
                </li>
              </ul>
              <div className="totalValue">
                <div className="lh1 labelText">Total Recurring Amount</div>
                <div className="fw600 value">${totalBalance.length > 0 && fromWei(totalBalance[2])}</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="w-100% t04 lgBlock tEarning">
            <div className="upperContent">
              <div className="t05 thumbImg">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.24414 11.0217V24.8058C3.24414 27.4975 5.15664 28.6025 7.47997 27.2708L10.8091 25.3725C11.5316 24.9617 12.7358 24.9192 13.4866 25.3017L20.9241 29.0275C21.675 29.3958 22.8791 29.3675 23.6016 28.9567L29.7358 25.4433C30.515 24.99 31.1666 23.885 31.1666 22.9783V9.19415C31.1666 6.50249 29.2541 5.39749 26.9308 6.72915L23.6016 8.62749C22.8791 9.03832 21.675 9.08082 20.9241 8.69832L13.4866 4.98665C12.7358 4.61832 11.5316 4.64665 10.8091 5.05749L4.67497 8.57082C3.88164 9.02415 3.24414 10.1292 3.24414 11.0217Z"
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12.1265 5.66669V24.0834" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path d="M22.2842 9.37836V28.3334" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" />
                </svg>
                <span className="rounded-circle t03 lockIcon lockClose">
                  <svg className="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.70395 7.71957V5.67021C4.70395 3.45872 6.50355 1.65957 8.71561 1.65957C10.9277 1.65957 12.7273 3.45872 12.7273 5.67021V7.71957C12.7364 7.72338 12.7455 7.72687 12.7546 7.73068C13.3271 7.97276 13.8721 8.2638 14.3873 8.60052V5.67021C14.3873 2.54363 11.843 0 8.71561 0C5.58823 0 3.04395 2.54363 3.04395 5.67021V8.60052C3.55915 8.26385 4.10419 7.97282 4.67667 7.73068C4.68574 7.72687 4.69487 7.72338 4.70395 7.71957Z" />
                    <path
                      d="M8.715 8.57446C3.90183 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90183 26 8.715 26C13.5282 26 17.43 22.0992 17.43 17.2872C17.43 12.4753 13.5282 8.57446 8.715 8.57446ZM11.7082 19.1062L10.5344 20.2797L8.715 18.4607L6.89559 20.2797L5.7218 19.1062L7.54121 17.2872L5.7218 15.4683L6.89559 14.2948L8.715 16.1137L10.5344 14.2948L11.7082 15.4683L9.88879 17.2872L11.7082 19.1062Z" />
                  </svg>
                </span>
              </div>
              <h2 className="mb-0 boxTitle">Deposite</h2>
            </div>
            <div className="sparkline">
              <canvas id="landLocked"></canvas>
            </div>
            <div className="bottomContent">
              <ul className="list-inline lh1 mb-0">
                <li>
                  <div className="me-auto monthEarning">
                    <div className="lh1 labelText">Earning</div>
                    <div className="fw600 value">${formatDecimal(earnings.d)}</div>
                  </div>
                </li>
                <li className="partition"></li>
                <li>
                  <div className="ms-auto monthEarning">
                    <div className="lh1 labelText">Withdraw</div>
                    <div className="fw600 value">${fromWei(userData?.user?.withdrawDepostie)}</div>
                  </div>
                </li>
              </ul>
              <div className="totalValue">
                <div className="lh1 labelText">Total Deposite</div>
                <div className="fw600 value">${totalBalance.length > 0 && fromWei(totalBalance[1])}</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="w-100% t04 lgBlock overallSum">
            <span className="rounded-circle t03 lockIcon lockOpen">
              <svg className="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"
                />
                <path
                  d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"
                />
              </svg>
            </span>
            <h2 className="fw600 mb-0 title">Total Withdrawal</h2>
            <div className="progressBar progressBar--withdrawl">
              <div className="progressBar__count">${totalBalance.length > 0 && fromWei(totalBalance[3])}</div>
              <div className="progressBar__graybg"></div>
              <canvas id="withdrawlChart"></canvas>
            </div>
            <div className="bottomContent">
              <div className="lastMonthInfo">
                <div className="textInfo">
                  <div className="lh1 labelText">No Of Investment</div>
                  <div className="fw600 value">{Number(userData?.user?.numberofinvest)}</div>
                </div>
                {/* <div className="m-0 sparkline">
                  <canvas id="lastMonth"></canvas>
                </div> */}
              </div>
              <div className="historyInfo">
                <ul className="list-inline">
                  <li>
                    <button className="btn arrowBtns" type="button">
                      <img src="/assets/images/iconSvgs/ArrowUp.svg" alt="" />
                    </button>
                  </li>
                  <li>
                    <button className="btn arrowBtns" type="button">
                      <img src="/assets/images/iconSvgs/ArrowUp.svg" alt="" />
                    </button>
                  </li>
                  <li>
                    <button onClick={() => window.location.href = "/stackingFD"} className="btn text-nowrap bg-white viewDetailBtn" type="button">
                      View History
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}
