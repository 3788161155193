import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Web3Modal } from '@web3modal/react';
import { bscTestnet, bsc } from "wagmi/chains";
import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum';
import { configureChains, createClient, WagmiConfig } from 'wagmi';

const chains = [bscTestnet, bsc];
const { provider, webSocketProvider } = configureChains(chains, [
  walletConnectProvider({ projectId: "d1e1ab6e89ccb5e27b14bcb9f8460a0c" }),
]);

// Wagmi client
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "The Beta World", chains }),
  provider,
  webSocketProvider
});

const ethereumClient = new EthereumClient(wagmiClient, chains);
const mobileWallets = [
  {
    id: 'metamask',
    name: 'MetaMask',
    links: { native: 'metamask://', universal: 'https://metamask.app.link' }
  },
  {
    id: 'trust',
    name: 'Trust Wallet',
    links: { native: 'trust://', universal: 'https://link.trustwallet.com' }
  },
  {
    id: 'rainbow',
    name: 'Rainbow',
    links: { native: 'rainbow://', universal: 'https://rainbow.me' }
  },
  {
    id: 'zerion',
    name: 'Zerion',
    links: { native: 'zerion://', universal: 'https://wallet.zerion.io' }
  },
  {
    id: 'tokenary',
    name: 'Tokenary',
    links: { native: 'tokenary://', universal: 'https://tokenary.io' }
  }
];
// Custom Linking Desktop Wallets
const desktopWallets = [
  {
    id: 'ledger',
    name: 'Ledger Live',
    links: { native: 'ledgerlive://', universal: 'https://www.ledger.com' }
  },
  {
    id: 'zerion',
    name: 'Zerion',
    links: { native: 'zerion://', universal: 'https://wallet.zerion.io' }
  },
  {
    id: 'tokenary',
    name: 'Tokenary',
    links: { native: 'tokenary://', universal: 'https://tokenary.io' }
  },
  {
    id: 'oreid',
    name: 'OREID',
    links: {
      native: '',
      universal: 'https://www.oreid.io/'
    }
  }
];
// Custom Wallet Images
const walletImages = {
  metamask: 'https://web3modal-dev.pages.dev/images/wallet_metamask.webp',
  brave: 'https://web3modal-dev.pages.dev/images/wallet_brave.webp',
  ledger: 'https://web3modal-dev.pages.dev/images/wallet_ledger.webp',
  coinbaseWallet: 'https://web3modal-dev.pages.dev/images/wallet_coinbase.webp',
  zerion: 'https://web3modal-dev.pages.dev/images/wallet_zerion.webp',
  trust: 'https://web3modal-dev.pages.dev/images/wallet_trust.webp',
  rainbow: 'https://web3modal-dev.pages.dev/images/wallet_rainbow.webp',
  oreid: 'https://web3modal-dev.pages.dev/images/wallet_oreid.svg'
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Web3Modal ethereumClient={ethereumClient} projectId="d1e1ab6e89ccb5e27b14bcb9f8460a0c"
      mobileWallets={mobileWallets}
      desktopWallets={desktopWallets}
      walletImages={walletImages} />
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
