import React from 'react'
import Header from '../components/Header'

function Defi() {
    return (
        <div className="w-100 dashboardCover">
            <Header active="defi" />

            <main className="w-100 mainContent">
                <div className="container">
                    <div className="topRow">
                        <ul className="list-unstyled smallBlockList">
                            <li>
                                <div className="t06 sBlock bitcoin">
                                    <div className="t04 thumbImg">
                                        <svg width={15} height={19} viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.1642 7.4369C14.4467 5.54537 13.0065 4.52853 11.0372 3.85019L11.676 1.28787L10.1159 0.899169L9.49398 3.39396C9.0844 3.29179 8.66327 3.19539 8.24481 3.09988L8.87117 0.588652L7.31237 0.199951L6.67312 2.76138C6.33373 2.68409 6.00056 2.60768 5.67716 2.52727L5.67894 2.51928L3.52797 1.9822L3.11306 3.64806C3.11306 3.64806 4.27028 3.91327 4.24585 3.92971C4.87754 4.08741 4.99216 4.50543 4.97261 4.83682L4.24496 7.75586C4.2885 7.76696 4.34491 7.78296 4.4071 7.80783L4.24274 7.76696L3.22234 11.8561C3.14505 12.048 2.94914 12.3359 2.50758 12.2266C2.52313 12.2492 1.3739 11.9436 1.3739 11.9436L0.599609 13.7294L2.62974 14.2354C2.8589 14.2928 3.08527 14.3519 3.30916 14.4103L3.30928 14.4103C3.45427 14.4481 3.59822 14.4857 3.7412 14.5224L3.09574 17.1145L4.65365 17.5032L5.29334 14.9391C5.71847 15.0546 6.13161 15.1612 6.53585 15.2616L5.89883 17.8137L7.45852 18.2024L8.10399 15.6156C10.7636 16.1189 12.764 15.9159 13.6049 13.5109C14.2832 11.574 13.5716 10.4568 12.1722 9.7278C13.1913 9.49191 13.9589 8.82157 14.1637 7.4369H14.1642ZM10.5987 12.4336C10.1559 14.2113 7.37285 13.4758 6.10059 13.1395C5.98666 13.1094 5.88485 13.0825 5.79791 13.0609L6.65439 9.62745C6.76064 9.65397 6.89046 9.6831 7.03752 9.7161C8.35371 10.0115 11.0511 10.6167 10.5992 12.4336H10.5987ZM7.30179 8.12319C8.36336 8.4064 10.6773 9.02372 11.0804 7.40886C11.4925 5.75681 9.24245 5.25887 8.14354 5.01569C8.02 4.98835 7.91101 4.96423 7.82199 4.94206L7.04547 8.05611C7.11895 8.07441 7.20518 8.09742 7.30179 8.12319Z" fill />
                                        </svg>
                                    </div>
                                    <div className="briefInfo">
                                        <span className="labelText">Bitcoin (BTC)</span>
                                        <span className="fw700 value">$20,994.83</span>
                                    </div>
                                    <img src="/assets/images/iconSvgs/bitcoinBg.svg" alt="" className="t04 bgIcon" />
                                </div>
                            </li>
                            <li>
                                <div className="t06 sBlock betaWorld">
                                    <div className="t04 thumbImg">
                                        <svg width={16} height={22} viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.06316 6.04092V9.81824L9.34127 11.1337V1.9023L7.132 0.626694L7.06316 0.581177V3.81572C3.47928 4.35395 0.729492 7.44739 0.729492 11.1809C0.729492 14.4462 2.83237 17.2216 5.75682 18.2269L5.7682 15.8561C4.07612 14.9821 2.91885 13.2161 2.91885 11.1809C2.91942 8.65984 4.69458 6.55241 7.06316 6.04092Z" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6474 4.08826L10.636 6.45911C12.3281 7.33304 13.4853 9.09909 13.4853 11.1343C13.4853 13.6553 11.7096 15.7627 9.34159 16.2742V12.4969L7.06348 11.1815V20.4129L9.27275 21.6885L9.34159 21.734V18.4994C12.9255 17.9612 15.6753 14.8678 15.6753 11.1343C15.6747 7.869 13.5718 5.09361 10.6474 4.08826Z" />
                                        </svg>
                                    </div>
                                    <div className="briefInfo">
                                        <span className="labelText">Beta World (BW)</span>
                                        <span className="fw700 value">$53,87.04</span>
                                    </div>
                                    <img src="/assets/images/iconSvgs/betaWorldBg.svg" alt="" className="t04 bgIcon" />
                                </div>
                            </li>
                            <li>
                                <div className="t06 sBlock etherium">
                                    <div className="t04 thumbImg">
                                        <img src="./images/etheriumLogo.svg" alt="" />
                                    </div>
                                    <div className="briefInfo">
                                        <span className="labelText">Etherium (ETC)</span>
                                        <span className="fw700 value">$12,994.83</span>
                                    </div>
                                    <img src="/assets/images/iconSvgs/etheriumBg.svg" alt="" className="t04 bgIcon" />
                                </div>
                            </li>
                            <li>
                                <div className="t06 sBlock binance">
                                    <div className="t04 thumbImg">
                                        <svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.27242 8.46763L10.0015 4.74016L13.7322 8.47067L15.9008 6.3006L10.0015 0.400024L4.10229 6.29908L6.27242 8.46763ZM0.399902 10.0003L2.56924 7.83102L4.73858 10.0003L2.56924 12.1696L0.399902 10.0003ZM10.002 15.2614L6.27285 11.5324L4.09968 13.6995L4.10271 13.7025L10.002 19.6L15.9012 13.6995L15.9027 13.6979L13.7326 11.5309L10.002 15.2614ZM15.2608 10.0009L17.4302 7.83167L19.5995 10.0009L17.4302 12.1702L15.2608 10.0009ZM10.0021 7.7974L12.2025 9.99931H12.2041L12.2025 10.0008L10.0021 12.2027L7.80162 10.0039L7.79858 9.99931L7.80162 9.99628L8.18681 9.6111L8.37486 9.42457L10.0021 7.7974Z" fill />
                                        </svg>
                                    </div>
                                    <div className="briefInfo">
                                        <span className="labelText">Binance (BNB)</span>
                                        <span className="fw700 value">$24,900.04</span>
                                    </div>
                                    <img src="/assets/images/iconSvgs/binanceBg.svg" alt="" className="t04 bgIcon" />
                                </div>
                            </li>
                        </ul>
                        <ul className="list-unstyled largeBlockList">
                            <li>
                                <div className="w-100% t04 lgBlock tEarning">
                                    <div className="upperContent">
                                        <div className="t05 thumbImg">
                                            <svg width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.333 16.1502C11.333 17.241 12.183 18.1335 13.2172 18.1335H15.3422C16.2488 18.1335 16.9855 17.3544 16.9855 16.4052C16.9855 15.371 16.5322 15.0027 15.8663 14.7619L12.4663 13.5719C11.7863 13.331 11.333 12.9627 11.333 11.9285C11.333 10.9794 12.0697 10.2002 12.9763 10.2002H15.1013C16.1497 10.2144 16.9997 11.0927 16.9997 12.1835" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.167 18.2041V19.2524" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.167 9.08105V10.1861" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.1522 25.4718C20.4036 25.4718 25.4713 20.4041 25.4713 14.1527C25.4713 7.90126 20.4036 2.8335 14.1522 2.8335C7.90077 2.8335 2.83301 7.90126 2.83301 14.1527C2.83301 20.4041 7.90077 25.4718 14.1522 25.4718Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.3887 28.1632C19.6637 29.9623 21.7462 31.1382 24.1262 31.1382C27.9937 31.1382 31.1387 27.9932 31.1387 24.1257C31.1387 21.774 29.977 19.6915 28.2062 18.4165" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span className="rounded-circle t03 lockIcon lockOpen">
                                                <svg className="t03" width={18} height={26} viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"  />
                                                    <path d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"  />
                                                </svg>
                                            </span>
                                        </div>
                                        <h2 className="mb-0 boxTitle">Beta Token</h2>
                                    </div>
                                    <div className="sparkline">
                                        <canvas id="totalEarning" />
                                    </div>
                                    <div className="bottomContent">
                                        <ul className="list-inline lh1 mb-0">
                                            <li>
                                                <div className="me-auto monthEarning">
                                                    <div className="lh1 labelText">NFT’S</div>
                                                    <div className="fw600 value">08</div>
                                                </div>
                                            </li>
                                            <li className="partition" />
                                            <li>
                                                <div className="ms-auto monthEarning">
                                                    <div className="lh1 labelText">Token</div>
                                                    <div className="fw600 value">6000</div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="totalValue">
                                            <div className="lh1 labelText">Total Earning</div>
                                            <div className="fw600 value">₹200000</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="w-100% t04 lgBlock tEarning">
                                    <div className="upperContent">
                                        <div className="t05 thumbImg">
                                            <svg width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.24414 11.0215V24.8056C3.24414 27.4973 5.15664 28.6023 7.47997 27.2706L10.8091 25.3723C11.5316 24.9615 12.7358 24.919 13.4866 25.3015L20.9241 29.0273C21.675 29.3956 22.8791 29.3673 23.6016 28.9565L29.7358 25.4431C30.515 24.9898 31.1666 23.8848 31.1666 22.9781V9.19397C31.1666 6.5023 29.2541 5.3973 26.9308 6.72897L23.6016 8.6273C22.8791 9.03814 21.675 9.08064 20.9241 8.69814L13.4866 4.98647C12.7358 4.61814 11.5316 4.64647 10.8091 5.0573L4.67497 8.57064C3.88164 9.02397 3.24414 10.129 3.24414 11.0215Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.127 5.6665V24.0832" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M22.2842 9.37842V28.3334" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span className="rounded-circle t03 lockIcon lockOpen">
                                                <svg className="t03" width={18} height={26} viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"  />
                                                    <path d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"  />
                                                </svg>
                                            </span>
                                        </div>
                                        <h2 className="mb-0 boxTitle">Land Locked</h2>
                                    </div>
                                    <div className="sparkline">
                                        <canvas id="betaToken" />
                                    </div>
                                    <div className="bottomContent">
                                        <ul className="list-inline lh1 mb-0">
                                            <li>
                                                <div className="me-auto monthEarning">
                                                    <div className="lh1 labelText">October</div>
                                                    <div className="fw600 value">₹8000</div>
                                                </div>
                                            </li>
                                            <li className="partition" />
                                            <li>
                                                <div className="ms-auto monthEarning">
                                                    <div className="lh1 labelText">November</div>
                                                    <div className="fw600 value">₹6000</div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="totalValue">
                                            <div className="lh1 labelText">Total Land Locked</div>
                                            <div className="fw600 value">20000<span className="fw400">sq.ft</span></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="w-100% t04 lgBlock overallSum">
                                    <span className="rounded-circle t03 lockIcon lockClose">
                                        <svg className="t03" width={18} height={26} viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.70395 7.71957V5.67021C4.70395 3.45872 6.50355 1.65957 8.71561 1.65957C10.9277 1.65957 12.7273 3.45872 12.7273 5.67021V7.71957C12.7364 7.72338 12.7455 7.72687 12.7546 7.73068C13.3271 7.97276 13.8721 8.2638 14.3873 8.60052V5.67021C14.3873 2.54363 11.843 0 8.71561 0C5.58823 0 3.04395 2.54363 3.04395 5.67021V8.60052C3.55915 8.26385 4.10419 7.97282 4.67667 7.73068C4.68574 7.72687 4.69487 7.72338 4.70395 7.71957Z" />
                                            <path d="M8.715 8.57446C3.90183 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90183 26 8.715 26C13.5282 26 17.43 22.0992 17.43 17.2872C17.43 12.4753 13.5282 8.57446 8.715 8.57446ZM11.7082 19.1062L10.5344 20.2797L8.715 18.4607L6.89559 20.2797L5.7218 19.1062L7.54121 17.2872L5.7218 15.4683L6.89559 14.2948L8.715 16.1137L10.5344 14.2948L11.7082 15.4683L9.88879 17.2872L11.7082 19.1062Z" />
                                        </svg>
                                    </span>
                                    <h2 className="fw600 text-center title">Token Locked</h2>
                                    <div className="progressBar progressBar--withdrawl">
                                        <div className="progressBar__count">22000</div>
                                        <div className="progressBar__graybg" />
                                        <canvas id="withdrawlChart" />
                                    </div>
                                    <div className="bottomContent">
                                        <div className="lastMonthInfo">
                                            <div className="textInfo">
                                                <div className="lh1 labelText">Last Month</div>
                                                <div className="fw600 value">₹3,48,00</div>
                                            </div>
                                            <div className="m-0 sparkline">
                                                <canvas id="lastMonth" />
                                            </div>
                                        </div>
                                        <div className="historyInfo">
                                            <ul className="list-inline">
                                                <li>
                                                    <button className="btn arrowBtns" type="button">
                                                        <img src="/assets/images/iconSvgs/ArrowUp.svg" alt="" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn arrowBtns" type="button">
                                                        <img src="/assets/images/iconSvgs/ArrowUp.svg" alt="" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn text-nowrap bg-white viewDetailBtn" type="button">
                                                        View History
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="w-100% t04 lgBlock overallSum lockedUnlocked">
                                    <span className="rounded-circle t03 lockIcon lockOpen">
                                        <svg className="t03" width={18} height={26} viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"  />
                                            <path d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"  />
                                        </svg>
                                    </span>
                                    <h2 className="fw600 d-flex align-items-center title">
                                        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z" fill="black" />
                                        </svg>
                                        Add Token
                                    </h2>
                                    <ul className="list-inline doughnutList">
                                        <li>
                                            <div className="doughnutEach">
                                                <div className="smallProgressBar smallProgressBar--withdrawl">
                                                    <div className="smallProgressBar__graybg" />
                                                    <canvas id="lockedUnlocked01" />
                                                </div>
                                                <div className="textHere">
                                                    <h3>Unlocked</h3>
                                                    <div className="fw700 value">33.33</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="doughnutEach">
                                                <div className="smallProgressBar smallProgressBar--withdrawl">
                                                    <div className="smallProgressBar__graybg" />
                                                    <canvas id="lockedUnlocked02" />
                                                </div>
                                                <div className="textHere">
                                                    <h3>Locked</h3>
                                                    <div className="fw700 value">20.00</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="doughnutEach">
                                                <div className="smallProgressBar smallProgressBar--withdrawl">
                                                    <div className="smallProgressBar__graybg" />
                                                    <canvas id="lockedUnlocked03" />
                                                </div>
                                                <div className="textHere">
                                                    <h3>Unlocked</h3>
                                                    <div className="fw700 value">40.00</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 mainTitleDT">
                                <h2 className="fw600 mb-0">Latest Proposal</h2>
                                <ul className="list-inline mb-0">
                                    <li>
                                        <form role="search">
                                            <input className="form-control t06" id="searchField2" type="search" placeholder="Search" aria-label="Search" />
                                            <button className="border-0 btnSearchNav position-relative btn t06" id="btnDTSearch" type="button">
                                                <span>
                                                    <i data-feather="search" />
                                                </span>
                                            </button>
                                        </form>
                                    </li>
                                    <li>
                                        <button className="btn bg-white">
                                            <img src="/assets/images/iconSvgs/Filter.svg" alt="Filter icon" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="dataTables_wrapper">
                                <table id="transactionDT" className="w-100 border-0 display dataTable" aria-describedby="example_info">
                                    <thead>
                                        <tr>
                                            <th className="sorting sorting_asc" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="ID: activate to sort column descending">Sr No.
                                            </th>
                                            <th className="sorting" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="NftType: activate to sort column ascending">
                                                Nft Type</th>
                                            <th className="sorting" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="NftValue: activate to sort column ascending">
                                                Nft Value</th>
                                            <th tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="DateBurn: activate to sort column ascending">
                                                Date of Burn</th>
                                            <th tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="TokenLocked: activate to sort column ascending">
                                                Token Locked</th>
                                            <th tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="TrxHash: activate to sort column ascending">
                                                Trx Hash</th>
                                            <th tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending">
                                                Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus rejected">Rejected</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus rejected">Locked</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>05</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>06</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>07</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>08</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>09</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td>Beetlejuice</td>
                                            <td>1988</td>
                                            <td>17-05-2022</td>
                                            <td>0x000...a0jf</td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Approved</a>
                                            </td>
                                            <td>
                                                <a href="./dao-screen1.html" className="tagStatus completed">Released</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    )
}

export default Defi