import React, { useState } from 'react'
import Header from '../components/Header'
import SideBar from '../components/SideBar'

export default function Property() {
	const [property, setProperty] = useState([
		{ city: 'Delhi', price: '$ 400', image: 'assets/images/property01.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property02.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property03.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property04.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property05.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property06.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property07.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
		{ city: 'Lahore', price: '$ 400', image: 'assets/images/property08.png', description: 'Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.' },
	]);
	return (
		<div className="w-100 dashboardCover">
			<Header active="property" />
			<main class="w-100 mainContent">
				<div class="container">
					<ul class="list-inline t04 viewProperties">
						{property.map((item, index) => {
							return (
								<li>
									<div class="propertyBox">
										<div class="innerWrap">
											<img src={item.image} alt="Property Image" />
											<div class="textContent">
												<div class="nameRow">
													<h2 class="fw700 mb-0">{item.city}</h2>
													<button class="btn rounded buyNow">
														<span class="">Buy Now</span>
														<div class="bdPartition">|</div>
														<span class="price fw700">{item.price}</span>
													</button>
												</div>
												<p class="mb-0">
													{item.description}
												</p>
											</div>
										</div>
									</div>
								</li>
							)
						})}
						{/* <li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property02.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property03.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property04.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property05.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property06.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property07.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="propertyBox">
								<div class="innerWrap">
									<img src="/assets/images/property08.png" alt="Property Image" />
									<div class="textContent">
										<div class="nameRow">
											<h2 class="fw700 mb-0">Lahore</h2>
											<button class="btn rounded buyNow">
												<span class="">Buy Now</span>
												<div class="bdPartition">|</div>
												<span class="price fw700">$ 400</span>
											</button>
										</div>
										<p class="mb-0">
											Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
										</p>
									</div>
								</div>
							</div>
						</li> */}
					</ul>
				</div>
			</main>
		</div>
	)
}