import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useAccount, useContract, useSigner } from 'wagmi';
import { APP_DATA, toWei } from '../../utils/constant';

function Deposits({ setLoading }) {
    const { address } = useAccount();
    const [active, setActive] = useState("");
    const [apy, setApy] = useState("");
    const [amount, setAmount] = useState("");

    const { data: signer } = useSigner()
    const contract = useContract({
        address: APP_DATA.contractAddress,
        abi: APP_DATA.abi,
        signerOrProvider: signer
    })
    const contractUSDT = useContract({
        address: APP_DATA.contractAddressUsdt,
        abi: APP_DATA.abi_usdt,
        signerOrProvider: signer
    })

    const onSubmit = () => {
        if (amount) {
            setLoading(true);
            const mAmount = toWei(amount);
            contract.DepositeItem(mAmount).then(res => {
                res.wait().then(res => {
                    setLoading(false);
                    window.location.reload();
                });
            })
        } else {
            toast.error("Please enter amount.");
        }
    }
    const onApprove = () => {
        setLoading(true);
        const mAmount = `1` + '0'.repeat(71);
        contractUSDT.approve(APP_DATA.contractAddress, mAmount).then(res => {
            res.wait().then(res => {
                setLoading(false);
                toast.success("Contract Approved Successfully.");
                getStatus();
            });
        })
    }

    const getStatus = () => {
        contractUSDT.allowance(address, APP_DATA.contractAddress).then(allowance => {
            if (Number(allowance) === 0) {
                setActive("approve")
            } else {
                setActive("submit");
            }
        })
    }

    useEffect(() => {
        if (signer) {
            contract.geteverything().then(res => { 
                console.log(res[1]);
                setApy(res[1])
             });
            getStatus();
        }
    }, [signer])

    const onAmountChange = (e) => {
        setAmount(e.target.value);
    }

    return (
        <div className="tabInner w-100 fdTabContent">
            <form method='post' onSubmit={onSubmit}>
                <div className="fieldSet">
                    <div className="bg-white form-group">
                        <label for="" className="fw500 label">APY</label>
                        <input type="text" value={Number(apy)} disabled className="border-0 t03 form-control form-control-smaller" />
                    </div>
                    <div className="bg-white form-group">
                        <label for="" className="fw500 label">Amount</label>
                        <input type="text"
                            value={amount}
                            onChange={onAmountChange}
                            className="border-0 t03 form-control form-control-smaller"
                            placeholder="0.00 - ₹ 0.00" />
                    </div>
                </div>
                <div className="mt-3 pt-1 btnGroup">
                    {
                        active === "submit" ?
                            <button type="button" onClick={onSubmit} className="fw500 themeBtn btn">Submit</button>
                            : <button type="button" onClick={onApprove} className="fw500 themeBtn btn">Approve</button>
                    }
                </div>
            </form>
        </div>
    )
}

export default Deposits