import { useWeb3Modal, Web3Button, Web3NetworkSwitch } from '@web3modal/react';
import { ethers } from 'ethers';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAccount, useContract, useSigner } from 'wagmi';
import { APP_DATA } from '../utils/constant';
const ModelViewer = require('@metamask/logo');

export default function Home() {
    const navigate = useNavigate();
    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount()
    const { data: signer } = useSigner()
    let contract = useContract({ address: APP_DATA.contractAddress, abi: APP_DATA.abi, signerOrProvider: signer })

    useEffect(() => {
        if(document.getElementById('logo-container').innerHTML !== "") return;
        const viewer = ModelViewer({
            // Dictates whether width & height are px or multiplied
            pxNotRatio: true,
            width: 500,
            height: 400,
            // pxNotRatio: false,
            // width: 0.9,
            // height: 0.9,

            // To make the face follow the mouse.
            followMouse: false,

            // head should slowly drift (overrides lookAt)
            slowDrift: false,
        });
        // Body Loaded
        document.querySelector("body").classList.add("loaded");
        const container = document.getElementById('logo-container');
        container.appendChild(viewer.container);
        // look at something on the page
        viewer.lookAt({
            x: 100,
            y: 100,
        });

        // enable mouse follow
        viewer.setFollowMouse(true);

        return () => {
            viewer.stopAnimation();
        }
    }, []);

    const checkForUserExit = async () => {
        const doesUserExist = await contract.doesUserExist(address);
        if (doesUserExist) {
            window.location.href = ("/profile");
        } else {
            window.location.href = ("/register");
        }
    }

    useEffect(() => {
        if (signer && isConnected) {
            checkForUserExit();
        }
    }, [signer, isConnected]);

    return (
        <>
            <div className="connectWalletCover">
                <div className="leftLogoArea">
                    <div className="innerSplash">
                        <img src="/assets/images/logo.svg" alt="Logo of Beta World" className="logoImage" />
                        <p>DeFi For Better Tomarrow</p>
                    </div>
                </div>
                <div className="loginArea">
                    <div className="text-center changeLayer">
                        {/* <img src="/assets/images/maskLogo.svg" alt="Mask Logo" /> */}
                        <div id="logo-container"></div>
                        <button onClick={open} className="btn t05 themeBtn">Connect Wallet</button>
                    </div>
                </div>
            </div>
        </>
    )
}