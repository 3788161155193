import React from 'react'
import Header from '../components/Header'

function Utility() {
    return (
        <div className="w-100 dashboardCover">
            <Header active="utility" />

            <main class="w-100 mainContent">
                <div class="container">
                    <div class="w-100 mainTitleDT">
                        <h2 class="fw600 mb-0">Utility Favorite Destinations</h2>
                    </div>
                    <ul class="list-inline t04 viewProperties utilityViewProps">
                        <li>
                            <div class="propertyBox clickableBox">
                                <div class="innerWrap">
                                    <span class="rounded-circle t03 lockIcon lockOpen">
                                        <svg class="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"
                                                ></path>
                                            <path
                                                d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"
                                                ></path>
                                        </svg>
                                    </span>
                                    <img class="t04" src="/assets/images/property01.png" alt="Property" />
                                    <div class="textContent">
                                        <div class="nameRow">
                                            <h2 class="fw700 mb-0">Lahore</h2>
                                            <button class="btn rounded buyNow">
                                                <span class="">Buy Now</span>
                                                <div class="bdPartition">|</div>
                                                <span class="price fw700">$ 400</span>
                                            </button>
                                        </div>
                                        <p class="mb-0">
                                            Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
                                        </p>
                                    </div>
                                    <div class="expandedContent">
                                        <div>
                                            <h2 class="fw700">Nature House</h2>
                                            <p>Lorem ipsum dolor sit amet consectetur. Etiam vel amet nisl.</p>
                                        </div>
                                        <ul class="list-inline">
                                            <li>
                                                <img src="/assets/images/iconSvgs/wifi.svg" alt="Wifi" />
                                                <span>Wifi</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/pool.svg" alt="Pool" />
                                                <span>Pool</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/dinner.svg" alt="Dinner" />
                                                <span>Dinner</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/ac.svg" alt="AC" />
                                                <span>AC</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/parking.svg" alt="Parking" />
                                                <span>Parking</span>
                                            </li>
                                        </ul>
                                        <div class="stPrice">
                                            <div class="lh1 title">
                                                <img src="/assets/images/iconSvgs/dollar-circle.svg" alt="" />
                                                <span>Starting Price</span>
                                            </div>
                                            <div class="price">
                                                <span class="text-nowrap fw700">5.16 ETH</span>
                                                <span class="fw300 line">|</span>
                                                <span class="text-nowrap fw700">$ 8,900.00</span>
                                            </div>
                                        </div>
                                        <button class="fw400 t04 themeBtn" type="button">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="propertyBox clickableBox">
                                <div class="innerWrap">
                                    <span class="rounded-circle t03 lockIcon lockOpen">
                                        <svg class="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.70254 7.71957V5.67021C4.70254 3.45872 6.50169 1.65957 8.71318 1.65957C10.9247 1.65957 12.7238 3.45872 12.7238 5.67021V7.71957C12.7329 7.72338 12.742 7.72687 12.7511 7.73068C13.3235 7.97276 13.8683 8.2638 14.3834 8.60052V5.67021C14.3834 2.54363 11.8398 0 8.71318 0C5.5866 0 3.04297 2.54363 3.04297 5.67021V8.60052C3.55805 8.26385 4.10294 7.97282 4.67527 7.73068C4.68434 7.72687 4.69347 7.72338 4.70254 7.71957Z"
                                                ></path>
                                            <path
                                                d="M8.71277 8.57446C3.90083 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90083 26 8.71277 26C13.5247 26 17.4255 22.0992 17.4255 17.2872C17.4255 12.4753 13.5247 8.57446 8.71277 8.57446ZM8.02415 20.304L4.77095 17.0508L5.94443 15.8773L8.02415 17.957L11.1239 14.8572L12.2974 16.0307L8.02415 20.304Z"
                                                ></path>
                                        </svg>
                                    </span>
                                    <img class="t04" src="/assets/images/property02.png" alt="Property" />
                                    <div class="textContent">
                                        <div class="nameRow">
                                            <h2 class="fw700 mb-0">Lahore</h2>
                                            <button class="btn rounded buyNow">
                                                <span class="">Buy Now</span>
                                                <div class="bdPartition">|</div>
                                                <span class="price fw700">$ 400</span>
                                            </button>
                                        </div>
                                        <p class="mb-0">
                                            Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
                                        </p>
                                    </div>
                                    <div class="expandedContent">
                                        <div>
                                            <h2 class="fw700">Nature House</h2>
                                            <p>Lorem ipsum dolor sit amet consectetur. Etiam vel amet nisl.</p>
                                        </div>
                                        <ul class="list-inline">
                                            <li>
                                                <img src="/assets/images/iconSvgs/wifi.svg" alt="Wifi" />
                                                <span>Wifi</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/pool.svg" alt="Pool" />
                                                <span>Pool</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/dinner.svg" alt="Dinner" />
                                                <span>Dinner</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/ac.svg" alt="AC" />
                                                <span>AC</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/parking.svg" alt="Parking" />
                                                <span>Parking</span>
                                            </li>
                                        </ul>
                                        <div class="stPrice">
                                            <div class="lh1 title">
                                                <img src="/assets/images/iconSvgs/dollar-circle.svg" alt="" />
                                                <span>Starting Price</span>
                                            </div>
                                            <div class="price">
                                                <span class="text-nowrap fw700">5.16 ETH</span>
                                                <span class="fw300 line">|</span>
                                                <span class="text-nowrap fw700">$ 8,900.00</span>
                                            </div>
                                        </div>
                                        <button class="fw400 t04 themeBtn" type="button">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="propertyBox clickableBox">
                                <div class="innerWrap">
                                    <span class="rounded-circle t03 lockIcon lockClose">
                                        <svg class="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.70395 7.71957V5.67021C4.70395 3.45872 6.50355 1.65957 8.71561 1.65957C10.9277 1.65957 12.7273 3.45872 12.7273 5.67021V7.71957C12.7364 7.72338 12.7455 7.72687 12.7546 7.73068C13.3271 7.97276 13.8721 8.2638 14.3873 8.60052V5.67021C14.3873 2.54363 11.843 0 8.71561 0C5.58823 0 3.04395 2.54363 3.04395 5.67021V8.60052C3.55915 8.26385 4.10419 7.97282 4.67667 7.73068C4.68574 7.72687 4.69487 7.72338 4.70395 7.71957Z">
                                            </path>
                                            <path
                                                d="M8.715 8.57446C3.90183 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90183 26 8.715 26C13.5282 26 17.43 22.0992 17.43 17.2872C17.43 12.4753 13.5282 8.57446 8.715 8.57446ZM11.7082 19.1062L10.5344 20.2797L8.715 18.4607L6.89559 20.2797L5.7218 19.1062L7.54121 17.2872L5.7218 15.4683L6.89559 14.2948L8.715 16.1137L10.5344 14.2948L11.7082 15.4683L9.88879 17.2872L11.7082 19.1062Z">
                                            </path>
                                        </svg>
                                    </span>
                                    <img class="t04" src="/assets/images/property02.png" alt="Property" />
                                    <div class="textContent">
                                        <div class="nameRow">
                                            <h2 class="fw700 mb-0">Lahore</h2>
                                            <button class="btn rounded buyNow">
                                                <span class="">Buy Now</span>
                                                <div class="bdPartition">|</div>
                                                <span class="price fw700">$ 400</span>
                                            </button>
                                        </div>
                                        <p class="mb-0">
                                            Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
                                        </p>
                                    </div>
                                    <div class="expandedContent">
                                        <div>
                                            <h2 class="fw700">Nature House</h2>
                                            <p>Lorem ipsum dolor sit amet consectetur. Etiam vel amet nisl.</p>
                                        </div>
                                        <ul class="list-inline">
                                            <li>
                                                <img src="/assets/images/iconSvgs/wifi.svg" alt="Wifi" />
                                                <span>Wifi</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/pool.svg" alt="Pool" />
                                                <span>Pool</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/dinner.svg" alt="Dinner" />
                                                <span>Dinner</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/ac.svg" alt="AC" />
                                                <span>AC</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/parking.svg" alt="Parking" />
                                                <span>Parking</span>
                                            </li>
                                        </ul>
                                        <div class="stPrice">
                                            <div class="lh1 title">
                                                <img src="/assets/images/iconSvgs/dollar-circle.svg" alt="" />
                                                <span>Starting Price</span>
                                            </div>
                                            <div class="price">
                                                <span class="text-nowrap fw700">5.16 ETH</span>
                                                <span class="fw300 line">|</span>
                                                <span class="text-nowrap fw700">$ 8,900.00</span>
                                            </div>
                                        </div>
                                        <button class="fw400 t04 themeBtn" type="button">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="propertyBox clickableBox">
                                <div class="innerWrap">
                                    <span class="rounded-circle t03 lockIcon lockClose">
                                        <svg class="t03" width="18" height="26" viewBox="0 0 18 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.70395 7.71957V5.67021C4.70395 3.45872 6.50355 1.65957 8.71561 1.65957C10.9277 1.65957 12.7273 3.45872 12.7273 5.67021V7.71957C12.7364 7.72338 12.7455 7.72687 12.7546 7.73068C13.3271 7.97276 13.8721 8.2638 14.3873 8.60052V5.67021C14.3873 2.54363 11.843 0 8.71561 0C5.58823 0 3.04395 2.54363 3.04395 5.67021V8.60052C3.55915 8.26385 4.10419 7.97282 4.67667 7.73068C4.68574 7.72687 4.69487 7.72338 4.70395 7.71957Z">
                                            </path>
                                            <path
                                                d="M8.715 8.57446C3.90183 8.57446 0 12.4753 0 17.2872C0 22.0992 3.90183 26 8.715 26C13.5282 26 17.43 22.0992 17.43 17.2872C17.43 12.4753 13.5282 8.57446 8.715 8.57446ZM11.7082 19.1062L10.5344 20.2797L8.715 18.4607L6.89559 20.2797L5.7218 19.1062L7.54121 17.2872L5.7218 15.4683L6.89559 14.2948L8.715 16.1137L10.5344 14.2948L11.7082 15.4683L9.88879 17.2872L11.7082 19.1062Z">
                                            </path>
                                        </svg>
                                    </span>
                                    <img class="t04" src="/assets/images/property02.png" alt="Property" />
                                    <div class="textContent">
                                        <div class="nameRow">
                                            <h2 class="fw700 mb-0">Lahore</h2>
                                            <button class="btn rounded buyNow">
                                                <span class="">Buy Now</span>
                                                <div class="bdPartition">|</div>
                                                <span class="price fw700">$ 400</span>
                                            </button>
                                        </div>
                                        <p class="mb-0">
                                            Lorem ipsum dolor sit amet consectetur. Is Posuere morbi est in ultricies.
                                        </p>
                                    </div>
                                    <div class="expandedContent">
                                        <div>
                                            <h2 class="fw700">Nature House</h2>
                                            <p>Lorem ipsum dolor sit amet consectetur. Etiam vel amet nisl.</p>
                                        </div>
                                        <ul class="list-inline">
                                            <li>
                                                <img src="/assets/images/iconSvgs/wifi.svg" alt="Wifi" />
                                                <span>Wifi</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/pool.svg" alt="Pool" />
                                                <span>Pool</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/dinner.svg" alt="Dinner" />
                                                <span>Dinner</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/ac.svg" alt="AC" />
                                                <span>AC</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/iconSvgs/parking.svg" alt="Parking" />
                                                <span>Parking</span>
                                            </li>
                                        </ul>
                                        <div class="stPrice">
                                            <div class="lh1 title">
                                                <img src="/assets/images/iconSvgs/dollar-circle.svg" alt="" />
                                                <span>Starting Price</span>
                                            </div>
                                            <div class="price">
                                                <span class="text-nowrap fw700">5.16 ETH</span>
                                                <span class="fw300 line">|</span>
                                                <span class="text-nowrap fw700">$ 8,900.00</span>
                                            </div>
                                        </div>
                                        <button class="fw400 t04 themeBtn" type="button">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </main>

        </div>
    )
}

export default Utility