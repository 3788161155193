import Web3 from "web3";
import abi from "../abis/abi.json";
import abi_usdt from "../abis/abi_usdt.json";
import abi_bloom from "../abis/abi_bloom.json";
import abi_wBloom from "../abis/abi_wBloom.json";
import abi_swap from "../abis/abi_swap.json";
import abi_eth from "../abis/abi_eth.json";
import abi_btc from "../abis/abi_btc.json";
import abi_dao from "../abis/abi_dao.json";
import { create } from 'ipfs-http-client';
import moment from "moment";

export const APP_DATA = {
    "abi": abi,
    contractAddress: "0x7272495CDaBD2c45D99CE2D7eb9DA450e9cDc97b",
    abi_dao: abi_dao,
    // "contractAddressDao": "0x2c7a91d0d65a6110c4714beba585c812ddfcc637",
    contractAddressDao: "0x8824278490908CAc5D24829F745a607837E18d8c",
    "abi_usdt": abi_usdt,
    "contractAddressUsdt": "0x12093e4a832296c6b5ced4fbb3e5bc0230f4c1a0",
    "abi_bloom": abi_bloom,
    "contractAddressBloom": "0x57a551270e2ab9ee346a5bb746d2b92c6fd2c6fa",
    "abi_wBloom": abi_wBloom,
    "contractAddressWBloom": "0x4a39ec420289fb5a392c0f07297d08aab22da8a7",
    abi_swap: abi_swap,
    contractAddressSwap: "0x959bAf3133a3D314F7767da816ABb216fE05720b",
    abi_eth,
    contractAddressEth: "0xc3d6b162a54c43A8fB54ccc061151Cb8886908c6",
    abi_btc,
    contractAddressBtc: "0xaa56D182025182b42A71b55d80C32920dC8E8dFB",
    loginCookieName: "loginAddress",
    ipfsURL: "https://braands.infura-ipfs.io/ipfs",
    bscScan: "https://testnet.bscscan.com",
}

export const createIPFSClient = () => {
    const projectId = '2DWJHtZRz0Y2jpsPSKCxopx5f69';
    const projectSecret = 'fbe3801f275d21e445197918b7334672';
    const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
    const client = create({
        host: "ipfs.infura.io",
        port: 5001,
        protocol: 'https',
        headers: {
            authorization: auth,
        }
    })

    return client;
}

export const formatAddr = (address) => {
    if (address) {
        return address.substr(0, 5) + "..." + address.substr(address.length - 4, 4);
    }
    return "";
}

export const getContract = (value = "MAIN") => {
    const web3 = new Web3(Web3.givenProvider);
    if (value === "MAIN") {
        return new web3.eth.Contract(abi, APP_DATA.contractAddress);
    } else if (value === "USDT") {
        return new web3.eth.Contract(abi_usdt, APP_DATA.contractAddressUsdt);
    } else if (value === "BLOOM") {
        return new web3.eth.Contract(abi_bloom, APP_DATA.contractAddressBloom);
    } else if (value === "WBLOOM") {
        return new web3.eth.Contract(abi_wBloom, APP_DATA.contractAddressWBloom);
    } else if (value === "SWAP") {
        return new web3.eth.Contract(abi_swap, APP_DATA.contractAddressSwap);
    } else if (value === "ETH") {
        return new web3.eth.Contract(abi_eth, APP_DATA.contractAddressEth);
    } else if (value === "BTC") {
        return new web3.eth.Contract(abi_btc, APP_DATA.contractAddressBtc);
    }
}

export const fromWei = (val) => {
    if(val){
        return new Web3(Web3.givenProvider).utils.fromWei(val.toString());
    } else {
        return 0;
    }
}
export const toWei = (val) => {
    return new Web3(Web3.givenProvider).utils.toWei(val.toString());
}

export const unixToDate = (val) => {
    let t = new Date(val * 1000);
    return moment(t);
}

export const formatDecimal = (val) => {
    return parseFloat(val).toFixed(2);
}

export const copyToClipboard = (str) => {
    window.navigator.clipboard.writeText(str);
}