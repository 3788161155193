import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAccount, useContract, useContractWrite, usePrepareContractWrite, useSigner } from 'wagmi';
import Web3 from 'web3';
import { APP_DATA } from '../utils/constant';

export default function Registration() {
    const navigate = useNavigate();
    const [input, setInput] = useState({
        username: '',
        sponser: ''
    })
    const { address, isConnected } = useAccount()
    const { data: signer } = useSigner()

    const contract = useContract({
        address: APP_DATA.contractAddress,
        abi: APP_DATA.abi,
        signerOrProvider: signer
    })

    useEffect(() => {
        if (isConnected) {
            window.addEventListener("load", () => {
                document.querySelector("body").classList.add("loaded");
            });
        } else {
            navigate("/");
        }
    }, []);

    const onChangeHandler = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const userNameExist = await contract.userNameExist(input.username);

        if (userNameExist) {
            alert("Username Already Exist!!!");
        } else {
            try {
                const trans = await contract.registration(input.username, input.sponser);
                trans.wait().then((res) => {
                    toast.success("Register Successfully.");
                    navigate("/profile");
                });
            } catch (error) {
                toast.error(error?.data?.message ? error?.data?.message : "Some Error Occured!!!");
            }
        }
    }

    return (
        <div className="connectWalletCover">
            <div className="leftLogoArea">
                <div className="innerSplash">
                    <img src="/assets/images/logo.svg" alt="Logo of Beta World" className="logoImage" />
                    <p>Lorem ipsum dolor sit amet consectetur. Id euismod quam diam molestie.</p>
                </div>
            </div>
            <div className="loginArea">
                <div className="text-center changeLayer">
                    <form method='post' onSubmit={onSubmit}>
                        <span className="t04">Registration</span>
                        <div className="mt-3 fieldSet">
                            <div className="bg-white form-group">
                                <input type="text" onChange={onChangeHandler} name="username" value={input.username} className="border-0 text-left t03 form-control" placeholder="Username" />
                            </div>
                        </div>
                        <div className="mt-3 fieldSet">
                            <div className="bg-white form-group">
                                <input type="text" onChange={onChangeHandler} name="sponser" value={input.sponser} className="border-0 text-left t03 form-control" placeholder="Sponser Address" />
                            </div>
                        </div>
                        <div className="mt-3 fieldSet">
                            <button type="submit" className="fw500 themeBtn btn">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}