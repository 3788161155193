import React from 'react'
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopChartView from '../components/TopChartView';

export default function Profile() {
	return (
		<div className="w-100 dashboardCover">
			<SideBar />
			<Header active="profile" />

			<main className="w-100 mainContent">
				<div className="container containerCustom">
					<TopChartView />
					<div className="lowerRow">
						<div className="pnlChartBox">
							<div className="headingRow">
								<h2 className="fw700 mb-0">
									<img src="/assets/images/logoHeader.png" alt="Dysan" />
									<span>PNL Analysis</span>
								</h2>
								<ul className="list-inline m-0">
									<li>
										<div className="fw700 textLabel">
											<span>Time</span>
											<i data-feather="chevron-right"></i>
										</div>
									</li>
									<li><a href="" className="fw500">4H</a></li>
									<li><a href="" className="fw500">24H</a></li>
									<li><a href="" className="fw500">7W</a></li>
									<li><a href="" className="fw500 active">1M</a></li>
									<li><a href="" className="fw500">1Y</a></li>
								</ul>
							</div>
							<div className="subHeading">
								<h3 className="m-0 fw700">
									<span>Latest Prices</span>
									<a href="#" className="lh1" data-bs-toggle="tooltip" data-bs-title="Some Info. in tooltip">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g opacity="0.4">
												<path fillRule="evenodd" clipRule="evenodd"
													d="M9 16.5001C13.1421 16.5001 16.5 13.1423 16.5 9.00012C16.5 4.85799 13.1421 1.50012 9 1.50012C4.85786 1.50012 1.5 4.85799 1.5 9.00012C1.5 13.1423 4.85786 16.5001 9 16.5001Z"
													stroke="#181818" strokeWidth="1.5" strokeLinecap="round"
													strokeLinejoin="round" />
												<path d="M9 6.00012V9.00012" stroke="#181818" strokeWidth="1.5"
													strokeLinecap="round" strokeLinejoin="round" />
												<circle cx="9" cy="12" r="0.75" fill="#181818" />
											</g>
										</svg>
									</a>
								</h3>
								<div className="dateOptionsSelect">
									<div className="labelText fw500">Provisions Month</div>
									<div className="form-group">
										<input type="text" className="rounded form-control" />
										<div className="calenderIcon">
											<i data-feather="calendar"></i>
										</div>
									</div>
									<button className="btn rounded moreBtn">
										<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd"
												d="M7.00016 8.16671C7.64449 8.16671 8.16683 7.64437 8.16683 7.00004C8.16683 6.35571 7.64449 5.83337 7.00016 5.83337C6.35583 5.83337 5.8335 6.35571 5.8335 7.00004C5.8335 7.64437 6.35583 8.16671 7.00016 8.16671Z"
												stroke="#131313" strokeWidth="1.16667" strokeLinecap="round"
												strokeLinejoin="round" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M11.6667 8.16671C12.311 8.16671 12.8333 7.64437 12.8333 7.00004C12.8333 6.35571 12.311 5.83337 11.6667 5.83337C11.0223 5.83337 10.5 6.35571 10.5 7.00004C10.5 7.64437 11.0223 8.16671 11.6667 8.16671Z"
												stroke="#131313" strokeWidth="1.16667" strokeLinecap="round"
												strokeLinejoin="round" />
											<path fillRule="evenodd" clipRule="evenodd"
												d="M2.33317 8.16671C2.9775 8.16671 3.49984 7.64437 3.49984 7.00004C3.49984 6.35571 2.9775 5.83337 2.33317 5.83337C1.68884 5.83337 1.1665 6.35571 1.1665 7.00004C1.1665 7.64437 1.68884 8.16671 2.33317 8.16671Z"
												stroke="#131313" strokeWidth="1.16667" strokeLinecap="round"
												strokeLinejoin="round" />
										</svg>
									</button>
								</div>
							</div>
							<div className="fullChart">
								<canvas id="pnlAnalysis"></canvas>
							</div>
						</div>
						<div className="pnlBarBox">
							<div className="speedometer">
								<div className="speedometer__inner">
									<svg className="speedometer__dashes" width="244" height="194" viewBox="0 0 244 194"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M23.6049 192.142C23.9816 191.765 26.2735 189.787 27.3724 188.845M13.2443 175.659L17.9537 173.304M6.18029 157.763C6.55704 157.763 9.79079 156.821 11.3606 156.35M2.41281 139.397L7.59309 138.455M1 120.559C1.37675 120.183 4.6105 120.402 6.18029 120.559M2.88374 101.251L7.59309 102.193M7.12216 82.8846L11.8315 84.2974M14.6571 64.9891L18.8955 67.3437M24.5468 48.5063L28.7852 51.8029M37.7329 34.8492L41.0295 38.1457M52.3319 22.6049L55.1575 26.3724M68.8147 12.7152L71.1693 16.9537M86.2393 5.65122L87.6521 10.3606M104.606 1.41281L105.548 6.12216M122.501 0C122.501 0.753496 122.501 3.76748 122.501 5.18029M141.339 1.41281L140.397 6.12216M160.176 5.65122C160.176 6.40472 158.92 9.4187 158.292 10.8315M177.601 13.1862L175.246 17.8955M194.083 23.5468L190.787 27.7852M208.211 35.7911C208.211 36.1678 205.7 38.4597 204.444 39.5586M220.927 50.3901L216.688 53.6866M230.816 66.8728L226.107 69.2275M237.88 84.7683L232.7 86.1811M242.119 103.135L236.939 104.077M243.532 121.972H238.351M241.648 141.281L236.468 140.339M237.88 160.118L232.229 157.763M229.874 177.543L225.165 175.188M219.985 193.554L215.746 190.729"
											stroke="#181818" strokeWidth="0.50279" />
									</svg>
									<div className="speedometer__grayarea">
										<div className="speedometer__whitearea">
											<div className="speedometer__count">
												<div className="speedometer__countTop"><span
													className="speedometer__countNum">22.11</span><span
														className="speedometer__countPer">%</span>
												</div>
												<div className="speedometer__countBottom">
													This Month
												</div>
											</div>
											<canvas id="pnlDonutGraph"></canvas>
										</div>
									</div>
								</div>
							</div>
							<div className="bottomContent progressDetail">
								<ul className="list-inline mb-0 lh1">
									<li>
										<div className="pnlInfo">
											<div className="lh1 labelText">Today’s PNL</div>
											<div className="fw700 value">8.24%</div>
										</div>
									</li>
									<li>
										<div className="pnlInfo">
											<div className="lh1 labelText">7 Days PNL</div>
											<div className="fw700 value redText">-0.56%</div>
										</div>
									</li>
									<li>
										<div className="pnlInfo">
											<div className="lh1 labelText">1 Month PNL</div>
											<div className="fw700 value">15.12%</div>
										</div>
									</li>
									<li>
										<div className="pnlInfo">
											<div className="lh1 labelText">1 Year PNL</div>
											<div className="fw700 value redText">12.48%</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</main>

		</div>
	)
}