import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAccount, useContract, useSigner } from 'wagmi';
import Header from '../components/Header'
import MyLoading from '../components/MyLoading';
import TopChartView from '../components/TopChartView';
import { APP_DATA, createIPFSClient, unixToDate } from '../utils/constant';

function ViewDao() {
  const { address } = useAccount();
  const { id } = useParams();
  const { data: signer } = useSigner()
  const contractDAO = useContract({ address: APP_DATA.contractAddressDao, abi: APP_DATA.abi_dao, signerOrProvider: signer })
  const contractBLOOM = useContract({ address: APP_DATA.contractAddressBloom, abi: APP_DATA.abi_bloom, signerOrProvider: signer })
  const [data, setData] = useState({});
  const [voters, setVoters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);

  const getData = async () => {
    const data = await contractDAO.idToPost(id);
    const proposalData = await axios.get(data.Proposal_Url);
    const isVoted = await contractDAO.checkVotedvoter(id);
    const endDays = getDateDeff(new Date(Number(data.endtime) * 1000), new Date());
    const isApproved = await contractDAO.isproposalPassed(Number(data.id));

    const proposalDataJson = { ...data, post: proposalData.data, isVoted, endDays, isApproved };
    setLoading(false);
    setData(proposalDataJson);
  }

  const getVoterList = async () => {
    try {
      const temp = await contractDAO.listofvote(id);
      setVoters(temp);
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
    }
  }

  useEffect(() => {
    if (signer) {
      getData();
      getVoterList();
    }
  }, [signer]);

  const addVote = async (vote) => {
    setLoading(true);
    const allowance = await contractBLOOM.allowance(address, APP_DATA.contractAddressDao);
    const mAmount = `1` + '0'.repeat(71);

    if (Number(allowance) === 0) {
      contractBLOOM.approve(APP_DATA.contractAddressDao, mAmount).then(res => {
        res.wait().then(() => {
          setLoading(false);
          toast.success("Approved successfully.");
          window.location.reload();
        }).catch((error) => {
          setLoading(false);
          toast.error(error.reason);
        });
      }).catch((error) => {
        setLoading(false);
        toast.error(error.reason);
      });
    } else {
      contractDAO.Vote(id, vote).then((res) => {
        res.wait().then(() => {
          setLoading(false);
          window.location.reload();
          toast.success('Vote added successfully');
        }).catch((error) => {
          setLoading(false);
          toast.error(error.reason);
        });
      }).catch((error) => {
        console.log(error, error.reason);
        setLoading(false);
        toast.error(error.reason);
      });
    }
  }

  const getDateDeff = (date2, date1) => {
    const Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  }

  return (
    <div className="w-100 dashboardCover">
      <Header active="dao" />
      {(loading || loading1) && <MyLoading />}
      <main className="w-100 mainContent">
        <div className="container">
          <TopChartView />
          <div className="sectionProposals">
            <div className="stProps">
              <section className="daoSection proposalSection">
                <div className="row daoSection__dates mb-3">
                  <div className="col-6">
                    {data.endDays < 0 ?
                      (
                        data.isApproved ?
                          <span className="daoSection__greenTxt">
                            <span className="daoSection__dot green" />
                            Approved
                          </span>
                          :
                          <span className="daoSection__redTxt">
                            <span className="daoSection__dot red" />
                            Rejected
                          </span>
                      )
                      :
                      <span className="daoSection__blueTxt">
                        <span className="daoSection__dot blue" />
                        Active
                      </span>}
                  </div>
                  <div className="col-6 text-end">
                    <span className="daoSection__yellowTxt">{data.endDays < 0 ? "Ended" : `Ending in ${data.endDays} days`}</span>
                  </div>
                </div>
                <div className="votingSection__text" dangerouslySetInnerHTML={{ __html: data?.post?.post ? data?.post?.post : data?.post }}></div>
              </section>
            </div>
            <div className="castingVote" style={{ height: "fit-content" }}>
              <section className="casteVoteSection">
                <h2>Cast your vote</h2>
                <div className="row casteVoteSection__dates">
                  <div className="col-6">
                    <span className="casteVoteSection__yellowTxt">Start Date :</span> <span className="casteVoteSection__blackTxt">{unixToDate(Number(data.startTime)).format("DD-MM-YYYY")}</span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="casteVoteSection__yellowTxt">End Date :</span> <span className="casteVoteSection__blackTxt">{unixToDate(Number(data.endtime)).format("DD-MM-YYYY")}</span>
                  </div>
                </div>
                <div className="casteVoteSection__txt">
                  <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                    Velit officia consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.</p>
                </div>
                <div>
                  <button disabled={data.isVoted || data.endDays < 0} onClick={() => addVote(0)} className="btn btn-success w-100 mb-3">Yes</button>
                  <button disabled={data.isVoted || data.endDays < 0} onClick={() => addVote(1)} className="btn btn-outline-danger w-100 mb-3">No</button>
                  <button className="btn btn-outline-success w-100">
                    <svg width={16} height={12} viewBox="0 0 16 12" fill="#1EB872" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.9973 1.02541L14.6602 1.36244L14.9973 1.02541C14.5745 0.602685 13.8892 0.602685 13.4665 1.02541L13.8015 1.36041L13.4665 1.02541L5.81283 8.67903L2.36872 5.23492C1.946 4.8122 1.26063 4.8122 0.837907 5.23492C0.415185 5.65764 0.415185 6.34301 0.837907 6.76573L5.04742 10.9752C5.47014 11.398 6.15551 11.398 6.57823 10.9752L14.9973 2.55622C15.42 2.1335 15.42 1.44813 14.9973 1.02541Z" strokeWidth="0.962175" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Successfully joined governance
                  </button>
                </div>
              </section>
              <section className="votingSection">
                <h2 className="mb-3">Current Results</h2>
                <div className="speedometer">
                  <div className="speedometer__inner">
                    <svg className="speedometer__dashes" width={244} height={194} viewBox="0 0 244 194" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.6049 192.142C23.9816 191.765 26.2735 189.787 27.3724 188.845M13.2443 175.659L17.9537 173.304M6.18029 157.763C6.55704 157.763 9.79079 156.821 11.3606 156.35M2.41281 139.397L7.59309 138.455M1 120.559C1.37675 120.183 4.6105 120.402 6.18029 120.559M2.88374 101.251L7.59309 102.193M7.12216 82.8846L11.8315 84.2974M14.6571 64.9891L18.8955 67.3437M24.5468 48.5063L28.7852 51.8029M37.7329 34.8492L41.0295 38.1457M52.3319 22.6049L55.1575 26.3724M68.8147 12.7152L71.1693 16.9537M86.2393 5.65122L87.6521 10.3606M104.606 1.41281L105.548 6.12216M122.501 0C122.501 0.753496 122.501 3.76748 122.501 5.18029M141.339 1.41281L140.397 6.12216M160.176 5.65122C160.176 6.40472 158.92 9.4187 158.292 10.8315M177.601 13.1862L175.246 17.8955M194.083 23.5468L190.787 27.7852M208.211 35.7911C208.211 36.1678 205.7 38.4597 204.444 39.5586M220.927 50.3901L216.688 53.6866M230.816 66.8728L226.107 69.2275M237.88 84.7683L232.7 86.1811M242.119 103.135L236.939 104.077M243.532 121.972H238.351M241.648 141.281L236.468 140.339M237.88 160.118L232.229 157.763M229.874 177.543L225.165 175.188M219.985 193.554L215.746 190.729" stroke="#181818" strokeWidth="0.50279" />
                    </svg>
                    <canvas id="currentResultsOuter" />
                    <div className="speedometer__grayarea">
                      <div className="speedometer__whitearea">
                        <div className="speedometer__count">
                          <div className="speedometer__countTop">
                            <span className="speedometer__countNum">
                              {Number(data.VoteFor) === 0 && Number(data.VoteAgainst) === 0 ? 0 : ((Number(data.VoteFor) * 100) / (Number(data.VoteFor) + Number(data.VoteAgainst))).toFixed(2)}
                            </span>
                            <span className="speedometer__countPer">%</span>
                          </div>
                          <div className="speedometer__countBottom">
                            This Month
                          </div>
                        </div>
                        <canvas id="currentResultsInner" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent  mt-2">
                  <div className="row">
                    <div className="col-6 text-center">
                      <div className="progressDetail__div1">Supported By</div>
                      <div className="progressDetail__div2">{Number(data.VoteFor)}</div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="progressDetail__div1">Rejected By</div>
                      <div className="progressDetail__div3">{Number(data.VoteAgainst)}</div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="votingSection votingSectionBelow">
                <h2 className="mb-3">Voting <span className="votingSection__num">{voters[0]?.length}</span></h2>
                <div id="transactionWrapper" className="dataTables_wrapper">
                  <table id="transactionDT" className="w-100 border-0 display dataTable" aria-describedby="example_info">
                    <thead>
                      <tr>
                        <th style={{ borderRadius: '0.625rem 0 0 0' }} className="sorting sorting_asc" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="ID: activate to sort column descending">Address
                        </th>
                        <th style={{ borderRadius: "0 0.625rem 0 0" }} className="sorting sorting_asc" rowspan="1" colspan="1" aria-label="Vote">Vote </th>
                      </tr>
                    </thead>
                    <tbody>
                      {voters[0]?.map((item, index) => (
                        <tr>
                          <td>
                            <a target="_blank" href>{item} <a href={APP_DATA.bscScan + "/address/" + item} target="_blank"><i className="bi bi-box-arrow-up-right" /></a> </a>
                          </td>
                          <td>
                            <label class="switch">
                              <input type="checkbox" checked={Number(voters[1][index]) === 0 ? true : false} />
                              <span class="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div >

      </main >
    </div >
  )
}

export default ViewDao