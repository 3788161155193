import React, { useEffect, useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai';
import Header from '../components/Header'
import { FaFacebookF, FaGoogle, FaLinkedin, FaPinterest, FaStar, FaTwitter } from 'react-icons/fa';
import { IoCartOutline } from 'react-icons/io5';
import ReactStars from "react-rating-stars-component";

export default function XRGlasses() {
  const data = {
    title: "VR Headset Compatible with iPhone & Android",
    finalPrice: "899",
    discount: "20",
    price: "1999",
    description: "Our Virtual Reality glasses with ergonomic T-shaped straps reduce some pressure around your eyes, face and head, when you using this Virtual Reality glasses without dizziness and fatigue. Also the straps can be adjustable and fit for different sizes of heads.",
    largeImages: [
      "/assets/images/products/xrglasses-1-sm.png",
      "/assets/images/products/xrglasses-2-sm.png",
      "/assets/images/products/xrglasses-3-sm.png",
      "/assets/images/products/xrglasses-4-sm.png",
    ],
    images: [
      "/assets/images/products/xrglasses-1-sm.png",
      "/assets/images/products/xrglasses-2-sm.png",
      "/assets/images/products/xrglasses-3-sm.png",
      "/assets/images/products/xrglasses-4-sm.png",
    ],
    totalReviews: 432,
  }
  const [quantity, setQuantity] = useState('1');
  const [activeImage, setActiveImage] = useState(0);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const container = document.getElementById("mainImage-container");
    const img = document.getElementById("mainImage");

    container.addEventListener("mousemove", (e) => onZoom(e, img));
    container.addEventListener("mouseover", (e) => onZoom(e, img));
    container.addEventListener("mouseleave", (e) => offZoom(e, img));
  }, []);

  function onZoom(e, img) {
    const x = e.clientX - e.target.offsetLeft;
    const y = e.clientY - e.target.offsetTop;

    img.style.transformOrigin = `${x}px ${y}px`;
    img.style.transform = "scale(2.5)";
  }

  function offZoom(e, img) {
    img.style.transformOrigin = `center center`;
    img.style.transform = "scale(1)";
  }

  const ratingChanged = (newRating) => {
    console.log(newRating)
  }

  return (
    <div className="w-100 dashboardCover concentricCircles">
      <Header active="xrglasses" />
      <main class="w-100 mainContent">
        <div class="container xr-glasses">
          <div className='row justify-content-center'>
            <div className='col-md-5'>
              <div id='mainImage-container'>
                <img id='mainImage' src={activeImage < data.images.length && data.largeImages[activeImage]} alt="XR Glasses" />
              </div>
              <div className='otherImages d-flex gap-3'>
                {data.images.map((image, index) => (
                  <div className={`otherImage ${activeImage === index ? "active" : ""}`} onClick={() => setActiveImage(index)} key={index}>
                    <img src={image} width="100%" alt="XR Glasses" />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-md-7 right-side ps-4'>
              <div className='heading'>
                <h1>{data.title}</h1>
              </div>
              <div className='description'>
                <p>{data.description}</p>
              </div>
              <hr />
              <div className='price'>
                <h2>INR : <span>₹ {data.finalPrice}</span></h2>
                <h4 className='mt-3'><span class="discount">{data.discount}%</span> <span className='discounted-price'><b>M.R.P:</b> ₹ {data.price}</span></h4>
              </div>
              <div className='reviews-section mt-4 pb-3'>
                <FaStar size={22} color="#EAA438" /> <span>4.6</span> &nbsp;|&nbsp;&nbsp;
                <span>{data.totalReviews} Reviews</span>
              </div>
              <div className='add-to-cart-section'>
                <div className='d-flex gap-3'>
                  <div className='product-quantity'>
                    <input type="text" value={quantity} onChange={(e) => { if (parseInt(e.target.value) >= 1) setQuantity(parseInt(e.target.value)); else setQuantity(e.target.value) }} />
                    <div className='quantity-buttons'>
                      <button onClick={() => setQuantity(ele => parseInt(ele) + 1)}>+</button>
                      <button onClick={() => setQuantity(ele => parseInt(ele) > 1 ? parseInt(ele) - 1 : ele)}>-</button>
                    </div>
                  </div>
                  <button className='btn t05 themeBtn'><IoCartOutline /> | Buy Now</button>
                </div>
                <button className='add-to-wishlist mt-4'><AiOutlineHeart /> Add to Wishlist</button>
              </div>
              <hr />
              <div className='details-section'>
                <table>
                  <tr>
                    <th>Colour </th>
                    <td>Clear </td>
                  </tr>
                  <tr>
                    <th>Mounting Hardware</th>
                    <td>3D VR glasses</td>
                  </tr>
                  <tr>
                    <th>Compatible Devices</th>
                    <td>Smartphone</td>
                  </tr>
                  <tr>
                    <th>Model</th>
                    <td>vr glasses</td>
                  </tr>
                  <tr>
                    <th>Operating System</th>
                    <td>Android</td>
                  </tr>
                </table>
              </div>
              <div className='social-icons'>
                <a className='pe-4' href='/'><FaFacebookF color='#555' /></a>
                <a className='pe-4' href='/'><FaTwitter color='#555' /></a>
                <a className='pe-4' href='/'><FaLinkedin color='#555' /></a>
                <a className='pe-4' href='/'><FaPinterest color='#555' /></a>
                <a className='pe-4' href='/'><FaGoogle color='#555' /></a>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-12'>
              <div className="product-information-tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li onClick={() => setTab(1)} className={`nav-item ${tab === 1 ? "active" : ""}`} role="presentation">Description</li>
                  <li onClick={() => setTab(2)} className={`nav-item ${tab === 2 ? "active" : ""}`} role="presentation">Additional Information</li>
                  <li onClick={() => setTab(3)} className={`nav-item ${tab === 3 ? "active" : ""}`} role="presentation">Reviews (0)</li>
                </ul>
              </div>
              <div className='tab-contents py-4'>
                {tab === 1 && <div className='tab-content'>
                  <b>About this item</b>
                  <ul className='mt-4'>
                    <li>Watch and Play in VR: HD optimization takes you special experience to watch Youtube and play.</li>
                    <li>Ergonomical Design: Our Virtual Reality glasses with ergonomic T-shaped straps reduce some pressure around your eyes, face and head, when you using this Virtual Reality glasses without dizziness and fatigue.also the straps can be adjustable and fit for different sizes of heads.</li>
                    <li>Virtual Reality Apps: There are over 300 virtual reality apps available for download and you can enjoy the amazing 3D effect! (Note: to get a clear and correct view, you need to select "Split Screen Mode" in the 3D VIRTUAL REALITY)</li>
                    <li>Upgraded Version: You can adjust the position of the resin spherical lens slightly by rolling the gear on the top of the 3D VIRTUAL REALITY BOX</li>
                  </ul>

                </div>}
                {tab === 2 && <div className='tab-content'>
                  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                </div>}
                {tab === 3 && <div className='tab-content'>
                  <h5>There are no reviews yet.</h5>
                  <h2 className='mt-4'>Be the first to review “{data.title}”</h2>
                  <p>Your email address will not be published. Required fields are marked *</p>
                  <div className='review-form'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <input type="text" placeholder='Name' className='form-control input-rounded' />
                      </div>
                      <div className='col-md-6'>
                        <input type="text" placeholder='Email' className='form-control input-rounded' />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-12'>
                        <label><b>Your Rating</b></label>
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={30}
                          activeColor="#FFD139"
                        />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-12'>
                        <textarea placeholder='Your Review' rows={4} className='form-control input-rounded' />
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-12'>
                        <button type="button" className='btn t05 px-4 themeBtn' style={{hieght: "50px"}}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}