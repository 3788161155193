import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useAccount, useContract, useSigner } from 'wagmi';
import Header from '../components/Header'
import { APP_DATA, unixToDate } from '../utils/constant';
import TopChartView from '../components/TopChartView';
import MyLoading from '../components/MyLoading';

function Dao() {
	const { address } = useAccount();
	const { data: signer } = useSigner()
	const contractDAO = useContract({ address: APP_DATA.contractAddressDao, abi: APP_DATA.abi_dao, signerOrProvider: signer })
	const contractBLOOM = useContract({ address: APP_DATA.contractAddressBloom, abi: APP_DATA.abi_bloom, signerOrProvider: signer })

	const [proposals, setProposals] = useState([]);
	const [myProposals, setMyProposals] = useState([]);
	const [completedProposals, setCompletedProposals] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loading1, setLoading1] = useState(true);
	const [completed, setCompleted] = useState({
		a: false, b: false, c: false
	})

	const getProposal = async () => {
		const myProposal1 = await contractDAO.ListMyPost();
		const myProposals1 = [];
		if (myProposal1.length === 0) {
			setLoading(false);
			setCompleted(prev=>({ ...prev, a: true }));
		}
		myProposal1?.forEach(async (element, i) => {
			const temp1 = await axios.get(element.Proposal_Url);
			const temp2 = { ...element, post: temp1.data };
			myProposals1.push(temp2);

			if (i === myProposal1.length - 1) {
				setLoading(false);
				setMyProposals(myProposals1);
				setCompleted(prev=>({ ...prev, a: true }));
			}
		});

		// Latest Proposal
		const totalProposal = await contractDAO.listActiveProposal();
		let prop = [];
		if (totalProposal.length === 0) {
			setLoading1(false);
			setCompleted(prev=>({ ...prev, b: true }));
		}

		totalProposal?.forEach(async (proposal, i) => {
			const proposalData = await axios.get(proposal.Proposal_Url);
			const isVoted = await contractDAO.checkVotedvoter(Number(proposal.id));

			const proposalDataJson = { ...proposal, post: proposalData.data, isVoted };
			prop.push(proposalDataJson);

			if (i === totalProposal.length - 1) {
				setLoading1(false);
				setProposals(prop);
				setCompleted(prev=>({ ...prev, b: true }));
			}
		})
	}

	const getCompletedProposal = async () => {
		// Latest Proposal
		const totalProposal = await contractDAO.listendProposal();
		let prop = [];
		if (totalProposal.length === 0) {
			setLoading1(false);
			setCompleted(prev=>({ ...prev, c: true }));
		}
		totalProposal?.forEach(async (proposal, i) => {
			const proposalData = await axios.get(proposal.Proposal_Url);
			const isVoted = await contractDAO.checkVotedvoter(Number(proposal.id));
			const isApproved = await contractDAO.isproposalPassed(Number(proposal.id));

			const proposalDataJson = { ...proposal, post: proposalData.data, isVoted, isApproved };
			prop.push(proposalDataJson);

			if (i === totalProposal.length - 1) {
				setLoading1(false);
				setCompletedProposals(prop);
				setCompleted(prev=>({ ...prev, c: true }));
			}
		})
	}

	useEffect(() => {
		if (signer) {
			getProposal();
			getCompletedProposal();
		}
	}, [signer])

	useEffect(() => {
		if (!document.getElementById("dataTablesScript") && completed.a && completed.b && completed.c) {
			const script = document.createElement("script");
			script.id = "dataTablesScript";
			script.innerHTML = `
				$('table.display').DataTable({
					scrollX: true,
					language: {
						paginate: {
							next: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>',
							previous: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>'
						}
					},
					"initComplete": function (settings, json) {

					}
				});`;
			script.async = true;
			document.body.appendChild(script);
		}
	}, [completed])

	return (
		<div className="w-100 dashboardCover">
			<Header active="dao" />
			{(loading || loading1) && <MyLoading />}
			<main className="w-100 mainContent">
				<div className="container">
					<TopChartView />
					<div className="div6040">
						<div className="div60">
							<h2 className="section-h2 mb-3">My Proposal</h2>
							<div id="transactionWrapper1" className="dataTables_wrapper">
								<table id="transactionDT" className="w-100 border-0 display dataTable" aria-describedby="example_info">
									<thead>
										<tr>
											<th className="sorting sorting_asc" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="ID: activate to sort column descending">Sr No.
											</th>
											<th className="sorting" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="Price: activate to sort column ascending">
												End Date</th>
											<th className="sorting" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="Amount: activate to sort column ascending">
												Proposal</th>
											<th className="sorting" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending">
												Status</th>
											<th className="sorting text-center" tabIndex={0} aria-controls="transactionDT" rowSpan={1} colSpan={1} aria-label="Amt Withdraw Date: activate to sort column ascending">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{myProposals?.map((proposal, index) => {
											return (
												<tr>
													<td>{index + 1}</td>
													<td>{unixToDate(Number(proposal.endtime)).format("DD-MM-YYYY")}</td>
													<td>{proposal?.post?.title ? proposal?.post?.title : ""}</td>
													<td>
														{!proposal.status ?
															<span className="tagStatus active">Active</span>
															: <span class="tagStatus rejected">Closed</span>
														}
													</td>
													<td className='text-center'>
														<a href={"/view-dao/" + Number(proposal.id)}>
															<i className="bi bi-eye" />
														</a>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="div40">
							<section className="daoSection submissionSection">
								<div>
									<h2 className="text-center mb-3">Submissions</h2>
									<div className="submissionSection__top">
										<div className="spreadFull">
											<div className="autonomous">
												<h4>Decentralize Autonomous Org</h4>
												<p>Amet minim mollit non deserunt ullamco est sit as aliqua dolor do amet
													yes it
													is great yes it is sint. Is Dolor do Is great yes it is sint.</p>
											</div>
											<div className="squareClick">
												<a href="./add-dao" className="submissionSection__uploadIcon">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														fill="none"
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														className="feather feather-plus-square"
														viewBox="0 0 24 24"
													>
														<rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect>
														<path d="M12 8L12 16"></path>
														<path d="M8 12L16 12"></path>
													</svg>
												</a>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div>
									<h3 className="mb-3 text-center">Submitted Proposals</h3>
									<div className="speedometer">
										<div className="row">
											<div className="col-md-6">
												<div className="speedometer__inner">
													<svg className="speedometer__dashes" width={244} height={194} viewBox="0 0 244 194" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M23.6049 192.142C23.9816 191.765 26.2735 189.787 27.3724 188.845M13.2443 175.659L17.9537 173.304M6.18029 157.763C6.55704 157.763 9.79079 156.821 11.3606 156.35M2.41281 139.397L7.59309 138.455M1 120.559C1.37675 120.183 4.6105 120.402 6.18029 120.559M2.88374 101.251L7.59309 102.193M7.12216 82.8846L11.8315 84.2974M14.6571 64.9891L18.8955 67.3437M24.5468 48.5063L28.7852 51.8029M37.7329 34.8492L41.0295 38.1457M52.3319 22.6049L55.1575 26.3724M68.8147 12.7152L71.1693 16.9537M86.2393 5.65122L87.6521 10.3606M104.606 1.41281L105.548 6.12216M122.501 0C122.501 0.753496 122.501 3.76748 122.501 5.18029M141.339 1.41281L140.397 6.12216M160.176 5.65122C160.176 6.40472 158.92 9.4187 158.292 10.8315M177.601 13.1862L175.246 17.8955M194.083 23.5468L190.787 27.7852M208.211 35.7911C208.211 36.1678 205.7 38.4597 204.444 39.5586M220.927 50.3901L216.688 53.6866M230.816 66.8728L226.107 69.2275M237.88 84.7683L232.7 86.1811M242.119 103.135L236.939 104.077M243.532 121.972H238.351M241.648 141.281L236.468 140.339M237.88 160.118L232.229 157.763M229.874 177.543L225.165 175.188M219.985 193.554L215.746 190.729" stroke="#181818" strokeWidth="0.50279" />
													</svg>
													<canvas id="submittedProposalsOuter" />
													<div className="speedometer__grayarea">
														<div className="speedometer__whitearea">
															<div className="speedometer__count">
																<div className="speedometer__countTop"><span className="speedometer__countNum">00.00</span><span className="speedometer__countPer">%</span>
																</div>
																<div className="speedometer__countBottom">
																	Proposals
																</div>
															</div>
															<canvas id="submittedProposalsInner" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6 text-center text-md-end autoInMobile">
												<div className="bottomContent submissionSection__counts ">
													<div className="row">
														<div className="col-6 col-md-12 text-center position-relative">
															<div className="progressDetail__div1 mb-2">Accepted Proposals</div>
															<div className="progressDetail__div2">00</div>
															<div className="separator" />
														</div>
														<div className="col-6 col-md-12 text-center">
															<div className="progressDetail__div1 mb-2">Rejected Proposals</div>
															<div className="progressDetail__div3">00</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>{/* .submissionSection */}
						</div>
					</div>
					<div className="row mt-4">
						<h2 className="section-h2 mb-3">Latest Proposal</h2>
						<div id="transactionWrapper2" className="dataTables_wrapper">
							<table id="transactionDT1" className="w-100 border-0 display dataTable" aria-describedby="example_info">
								<thead>
									<tr>
										<th className="sorting sorting_asc" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="ID: activate to sort column descending">Sr No.
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Price: activate to sort column ascending">
											Date of Proposal</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Price: activate to sort column ascending">
											End Date</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Amount: activate to sort column ascending">
											Voted</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending">
											No.of Votes
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Earning: activate to sort column ascending">
											Votes (Yes / No)
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Earning Withdraw: activate to sort column ascending">
											Status
										</th>
										<th className="sorting text-center" tabIndex={0} aria-controls="transactionDT1" rowSpan={1} colSpan={1} aria-label="Amt Withdraw Date: activate to sort column ascending">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{proposals?.map((proposal, index) => (
										<tr>
											<td>{index + 1}</td>
											<td>{unixToDate(proposal.startTime).format("DD-MM-YYYY")}</td>
											<td>{unixToDate(proposal.endtime).format("DD-MM-YYYY")}</td>
											<td>
												{proposal.isVoted ? (
													<span className="voted-status yes">
														<i className="bi bi-check-square-fill" />
														Yes
													</span>
												) : (
													<span className="voted-status no">
														<i className="bi bi-check-square-fill" />
														No
													</span>
												)}
											</td>
											<td>{Number(proposal.VoteFor) + Number(proposal.VoteAgainst)}</td>
											<td>{Number(proposal.VoteFor)} / {Number(proposal.VoteAgainst)}</td>
											<td>
												<span className="label-status active">Active</span>
											</td>
											<td className="text-center">
												<a href={"/view-dao/" + Number(proposal.id)}>
													<i className="bi bi-eye" />
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					<div className="row mt-4">
						<h2 className="section-h2 mb-3">Completed Proposal</h2>
						<div id="transactionWrapper3" className="dataTables_wrapper">
							<table id="transactionDT2" className="w-100 border-0 display dataTable" aria-describedby="example_info">
								<thead>
									<tr>
										<th className="sorting sorting_asc" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="ID: activate to sort column descending">Sr No.
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Price: activate to sort column ascending">
											Date of Proposal</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Price: activate to sort column ascending">
											End Date</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Amount: activate to sort column ascending">
											Voted</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending">
											No.of Votes
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Earning: activate to sort column ascending">
											Votes (Yes / No)
										</th>
										<th className="sorting" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Earning Withdraw: activate to sort column ascending">
											Status
										</th>
										<th className="sorting text-center" tabIndex={0} aria-controls="transactionDT2" rowSpan={1} colSpan={1} aria-label="Amt Withdraw Date: activate to sort column ascending">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{completedProposals?.map((proposal, index) => (
										<tr>
											<td>{index + 1}</td>
											<td>{unixToDate(proposal.startTime).format("DD-MM-YYYY")}</td>
											<td>{unixToDate(proposal.endtime).format("DD-MM-YYYY")}</td>
											<td>
												{proposal.isVoted ? (
													<span className="voted-status yes">
														<i className="bi bi-check-square-fill" />
														Yes
													</span>
												) : (
													<span className="voted-status no">
														<i className="bi bi-check-square-fill" />
														No
													</span>
												)}
											</td>
											<td>{Number(proposal.VoteFor) + Number(proposal.VoteAgainst)}</td>
											<td>{Number(proposal.VoteFor)} / {Number(proposal.VoteAgainst)}</td>
											<td>
												{proposal.isApproved ? (
													<span className="label-status yes">Approved</span>
												) : (
													<span className="label-status no">Rejected</span>
												)}
											</td>
											<td className="text-center">
												<a href={"/view-dao/" + Number(proposal.id)}>
													<i className="bi bi-eye" />
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div >
			</main >
		</div >
	)
}

export default Dao