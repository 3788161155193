import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { APP_DATA, copyToClipboard, formatAddr, fromWei, getContract } from '../utils/constant'
import { AiOutlineLeft } from 'react-icons/ai';
import { useAccount, useContract, useSigner } from 'wagmi';

function SideBar() {
    const [userData, setUserData] = useState({});
    const [userDownline, setUserDownline] = useState([]);
    const [userDownline1, setUserDownline1] = useState([]);
    const [userDownline2, setUserDownline2] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(0);
    const { address } = useAccount();
    const [selected, setSelected] = useState(null);

    const { data: signer } = useSigner()
    const contract = useContract({ address: APP_DATA.contractAddress, abi: APP_DATA.abi, signerOrProvider: signer })

    const sidebarShow = () => {
        var elementBar = document.getElementById("rightSideBar");
        elementBar.classList.toggle("sideBarExpand");
    }

    const getUserDownline = async () => {
        const myRes = await contract.getdownline(address);
        setUserDownline(myRes);
    }

    useEffect(() => {
        if (signer) {
            contract.getuplineearning(address).then((res) => {
                setUserData(res);
            });
            getUserDownline();
        }
    }, [signer]);

    const onClickDownline = async (addr, level) => {
        setSelected(addr);
        const myRes = await contract.getdownline(addr?.user?.myAddress);
        if (level === 1) {
            setCurrentLevel(1);
            setUserDownline1(myRes);
        } else if (level === 2) {
            setCurrentLevel(2);
            setUserDownline2(myRes);
        }
    }

    return (
        <>
            <div className="sideBar" id="rightSideBar">
                <div className="t06 sideMainText">
                    <div className="cardVoucher">
                        <div className="userInfoCard">
                            <div className="userContent">
                                <img src="/assets/images/logo.svg" alt="Logo" />
                                <div className="fw700 name">{userData?.user?.name}</div>
                                <div className="codeTag">
                                    <span>{formatAddr(address)}</span>
                                    <a className="p-0 btn">
                                        <img className="rounded-circle" src="/assets/images/iconSvgs/document-copy.svg" alt="Copy code" />
                                    </a>
                                </div>
                            </div>
                            <div className="glassMorphed">
                                <img src="/assets/images/avatarInSidebar.svg" alt="User DP" />
                            </div>
                        </div>
                        <div className="sAddress">
                            <div className="fw700 labelText">Sponser Address</div>
                            <div className="codeTag">
                                <span>{formatAddr(userData?.user?.sponsor_address)}</span>
                                <a className="p-0 btn" onClick={() => copyToClipboard(userData?.user?.sponsor_address)}>
                                    <img className="rounded-circle" src="/assets/images/iconSvgs/document-copy.svg" alt="Copy code" />
                                </a>
                            </div>
                        </div>
                        <div className="sAddress">
                            <div className="fw700 labelText">Total Earning From Downline</div>
                            <div className="codeTag">
                                <span>${userData?.d && fromWei(userData?.d)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="partition"></div>
                    <h2 className="fw600 text-center cardTitle">Downline</h2>
                    <div className="text-center lh1 subTitle">My Family</div>
                    {currentLevel === 0 ? <></> :
                        <>
                            <button className='border-0 t05' onClick={() => setCurrentLevel(prev => Number(prev) - 1)}>
                                <AiOutlineLeft />
                            </button>
                            {selected?.base?.name && <div className="text-center lh1 subTitle">Level {Number(currentLevel) + 1} - {selected?.base?.name}</div>}
                        </>}
                    <ul className="list-unstyled userList">
                        {
                            currentLevel === 0 ? <>
                                {userDownline.length>0 && userDownline[0].map((ele, i) => (
                                    <li className='cursor-pointer' key={i} onClick={() => onClickDownline(ele, 1)}>
                                        <div className="eachCard">
                                            <div className="lh1 uName">
                                                <span className="fw600 text-nowrap labelText">User Name:</span>
                                                <span className="value">{ele?.name}</span>
                                            </div>
                                            <div className="lh1">
                                                <span className="value">Earning: </span>
                                                <span className="value fw500">${userDownline[currentLevel + 1].length > 0 && Number(userDownline[currentLevel + 1][i])}</span>
                                            </div>
                                            <div className="codeTag">
                                                <span>{formatAddr(ele?.myAddress)}</span>
                                                <a className="p-0 btn" onClick={() => copyToClipboard(ele?.myAddress)}>
                                                    <img className="rounded-circle" src="/assets/images/iconSvgs/document-copy.svg" alt="Copy code" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </> : currentLevel === 1 ? <>
                                {userDownline1.length>0 && userDownline1[0].map((ele, i) => (
                                    <li className='cursor-pointer' key={i} onClick={() => onClickDownline(ele, 2)}>
                                        <div className="eachCard">
                                            <div className="lh1 uName">
                                                <span className="fw600 text-nowrap labelText">User Name:</span>
                                                <span className="value">{ele?.name}</span>
                                            </div>
                                            <div className="lh1">
                                                <span className="value">Earning: </span>
                                                <span className="value fw500">$0</span>
                                            </div>
                                            <div className="codeTag">
                                                <span>{formatAddr(ele?.myAddress)}</span>
                                                <a className="p-0 btn" onClick={() => copyToClipboard(ele?.myAddress)}>
                                                    <img className="rounded-circle" src="/assets/images/iconSvgs/document-copy.svg" alt="Copy code" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {userDownline1.length>0 && userDownline1[0].length === 0 && <div className="text-center">No Downline</div>}
                            </> : <>
                                {userDownline2.length>0 && userDownline2[0].map((ele, i) => (
                                    <li key={i}>
                                        <div className="eachCard">
                                            <div className="lh1 uName">
                                                <span className="fw600 text-nowrap labelText">User Name:</span>
                                                <span className="value">{ele.name}</span>
                                            </div>
                                            <div className="lh1">
                                                <span className="value">Earning: </span>
                                                <span className="value fw500">$0</span>
                                            </div>
                                            <div className="codeTag">
                                                <span>{formatAddr(ele.myAddress)}</span>
                                                <a className="p-0 btn" onClick={() => copyToClipboard(ele.myAddress)}>
                                                    <img className="rounded-circle" src="/assets/images/iconSvgs/document-copy.svg" alt="Copy code" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {userDownline2.length>0 && userDownline2[0].length === 0 && <div className="text-center">No Downline</div>}
                            </>
                        }
                    </ul>
                </div>
            </div>
            <button onClick={sidebarShow} className="t05 arrowLeft border-0">
                <AiOutlineLeft />
            </button>
        </>
    )
}

export default SideBar